var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto my-4 space-y-5 px-4 sm:px-4 lg:px-8"},[(_vm.loading.visible)?_c('loading',{attrs:{"text":_vm.loading.text}}):_vm._e(),_c('OrderCreateForm',{attrs:{"area":_vm.selectedArea,"destination-office-type":"kantor_pelayanan","force-shipment":""},on:{"change-address":_vm.setupArea,"change-order-shipment":_vm.setupArea,"change-destination":_vm.setupArea},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.canAddProduct)?[_c('OrderDetailTable',{attrs:{"create-order-payload":{
        order_type: 'sale',
        order_shipment: _vm.order.attributes.order_shipment,
        notes: _vm.order.attributes.notes,
        is_ppn_included: true,
        is_pre_order: false,
      },"origin-warehouse":{
        id: _vm.order.relationships.originWarehouse.id,
      },"destination-warehouse":{
        id: _vm.order.relationships.destinationWarehouse.id,
      },"origin-office":{
        id: _vm.order.relationships.originOffice.id,
        code: _vm.order.relationships.originOffice.attributes.code,
      },"destination-office":{
        id: _vm.order.relationships.destinationOffice.id,
        code: _vm.rawDestinationOfficeCode,
      },"buyer-type":{
        id: _vm.order.relationships.buyerType.id,
        code: _vm.order.relationships.buyerType.attributes.code,
      },"area":{
        id: _vm.order.relationships.area.id,
        code: _vm.order.relationships.area.attributes.code,
      },"order-id":_vm.order.id,"min-grand-total":0},on:{"order-created":_vm.handleCreatedOrder,"created":_vm.handleUpdatedOrderDetail,"order-updated":_vm.handleUpdatedOrderDetail},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}),_c('OrderPromoList',{attrs:{"order-details":_vm.orderDetailsForPromoList,"product-bundles":_vm.productBundles},on:{"change-selected-bundles":_vm.onChangeSelectedBundles,"change-choosen-bonuses":_vm.onChangeChoosenBonuses}}),_c('OrderCreateActionForm',{attrs:{"total-price":_vm.totalPrice,"min-order-spend":0,"cancel-redirect":"/gudang/restock/pusat-ke-kp"},on:{"process":_vm.handleProcessOrder}}),_c('OrderDetailModal',{attrs:{"visible":_vm.success,"order":_vm.getOrder.data,"editable":true,"deleteable":false,"validationIgnorePaymentStatus":true,"withPayments":false,"with-bonus":""},on:{"close":_vm.handleClose,"edit":function($event){_vm.success = false},"refresh":_vm.handleClose}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }