<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <BaseCard title="Riwayat Restock KP">
        <template #action>
          <base-button to="pusat-ke-kp/tambah">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            Tambah Restock
          </base-button>
        </template>
        <div class="flex flex-col">
          <div class="flex mt-2 items-end justify-end space-x-2">
            <base-input label="Status Faktur">
              <select
                v-model="order_status"
                @change="filterByCompleted"
                class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option
                  :value="'Draft, Diproses Kasir, Diproses Gudang Pengeluaran'"
                >
                  Semua
                </option>
                <option :value="'Diproses Kasir'">Valid</option>
                <option :value="'Diproses Gudang Pengeluaran'">
                  Diproses Gudang Pengeluaran
                </option>
                <option :value="'Draft'">Draft</option>
              </select>
            </base-input>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle sm:px-6 lg:px-8">
              <Datatable
                :total="getOrders.meta.page.total"
                :perPage="getOrders.meta.page.perPage"
                :currentPage="getOrders.meta.page.currentPage"
                @pagechanged="onPageChangeOrder"
                :is-empty="!getOrders.data.length"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        @click="handleSort('origin_code', sort.origin_code)"
                        scope="col"
                        class="flex cursor-pointer gap-x-4 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Restock KP
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Kode KP / Kode Gudang
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status Faktur
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status Laporan
                      </th>
                      <th
                        @click="
                          handleSort('warehouse_status', sort.warehouse_status)
                        "
                        scope="col"
                        class="flex cursor-pointer justify-center gap-x-4 px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status Proses
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <div v-if="isLoading" class="flex items-center text-center">
                    <loading></loading>
                  </div>
                  <tbody v-if="getOrders.data.length">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrders.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="changeSale(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        <order-code-column :value="data" />
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{
                          getRelationships(
                            data.relationships['destination-office'].data.id
                          )?.attributes.code
                        }}
                        /
                        {{
                          getRelationships(
                            data.relationships['origin-office'].data.id
                          )?.attributes.code
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.order_status"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Valid
                        </span>
                        <span
                          v-else-if="
                            !data.attributes.order_status &&
                            !data.attributes.canceled_by_user_id &&
                            !data.attributes.canceled_from
                          "
                          class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                        >
                          Draft
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-700"
                        >
                          Revisi
                        </span>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_completed"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Selesai
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum Selesai
                        </span>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <base-badge
                          :class="getClass(data.attributes.warehouse_status)"
                        >
                          {{ data.attributes.warehouse_status }}
                        </base-badge>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </BaseCard>

      <OrderDetailModal
        :visible="openDetail"
        :order="getOrder.data"
        :with-payments="false"
        with-bonus
        validation-ignore-payment-status
        @close="openDetail = !openDetail"
        @delete="confirmationDelete = !confirmationDelete"
        @edit="id => $router.push(`pusat-ke-kp/${id}`)"
        @refresh="handleValidated"
      />

      <!--DELETE MODAL CONFIRMATION-->
      <transition name="fade" class="z-20">
        <Modal2
          v-if="confirmationDelete"
          @close-modal="confirmationDelete = false"
          colorheading="bg-red-50"
          colorbody="bg-red-50"
        >
          <template v-slot:heading>
            <h3
              class="text-lg font-semibold leading-6 text-gray-900"
              id="modal-title "
            >
              Hapus faktur penjualan
            </h3>
          </template>
          <template v-slot:body>
            <div
              class="flex relative content-center items-center justify-center py-2"
            >
              <svg
                class="text-red-100"
                width="194"
                height="191"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                  fill="currentColor"
                />
              </svg>
              <div class="flex absolute w-full justify-center">
                <DocumentSuccess class="h-32" />
              </div>
            </div>
            <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
              Faktur penjualan dengan Nomor
              <span class="font-bold">{{
                dataPenjualan.attributes.origin_code
              }}</span>
              akan dihapus. Apakah anda yakin ingin melanjutkan?
            </h3>
          </template>
          <template v-slot:footer>
            <div>
              <button
                @click="deleteOrder"
                class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya, saya yakin
              </button>
              <button
                @click="confirmationDelete = !confirmationDelete"
                class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
              >
                Kembali
              </button>
            </div>
          </template>
        </Modal2>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import { DocumentSuccess } from '@/assets/icons';
import Modal2 from '@/components/Modal2.vue';
import BaseSearch from '@/components/base/Search.vue';
import OrderCodeColumn from '@/components/order/order-code-column.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    OrderDetailModal,
    BaseSearch,
    Modal2,
    DocumentSuccess,
    OrderCodeColumn,
  },

  data() {
    return {
      search: null,
      order_status: 'Diproses Gudang Pengeluaran',
      openDetail: false,
      confirmationDelete: false,
      dataPenjualan: {},
      sort: {
        origin_code: false,
        warehouse_status: false,
        order_status: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
    }),
    orderParams() {
      return {
        'page[limit]': 5,
        'filter[search]': this.search,
        'filter[order_status]': this.order_status,
        include: 'order,origin-office,destination-office',
        'filter[destination_office_category_id]': 2,
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      updateOrder: 'orders/updateOrder',
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      removeOrder: 'orders/deleteOrder',
    }),
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getClass(attribut) {
      return {
        'bg-yellow-100 text-yellow-800':
          attribut === 'Diproses Gudang' || attribut === 'Diproses Kasir',
        'bg-blue-100 text-blue-800':
          attribut === 'Dikirim' || attribut === 'Persiapan',
        'bg-red-100 text-red-800':
          attribut === 'Ada Susulan' ||
          attribut === 'Belum Masuk Gudang' ||
          attribut === 'Draft',
        'bg-green-100 text-green-800': attribut === 'Selesai',
      };
    },
    debounceSearch: debounce(function () {
      this.fetchOrders(this.orderParams);
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.orderParams,
        ...page,
      });
    },
    filterByCompleted() {
      this.fetchOrders(this.orderParams);
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      this.fetchOrder({ id: data.attributes.order_id }).then(() => {
        this.dataPenjualan = this.getOrder.data;
      });
    },
    handleValidated() {
      this.openDetail = false;
      this.fetchOrders(this.orderParams);
    },
    deleteOrder() {
      this.removeOrder(this.dataPenjualan.attributes.order_id).then(
        (response) => {
          this.confirmationDelete = !this.confirmationDelete;
          this.deleteStatus = true;
          if (response) {
            this.openDetail = !this.openDetail;
            this.fetchOrders(this.orderParams);
          } else {
            this.failed = true;
          }
        }
      );
    },
    handleSort(column, currentState) {
      this.sort[column] = !this.sort[column];

      this.fetchOrders({
        ...this.orderParams,
        sort: !currentState ? column : `-${column}`,
      });
    },
  },
  created() {
    this.fetchOrders(this.orderParams);

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
