<template>
  <BaseModal
    :showing="visible"
    :showClose="true"
    :backgroundClose="true"
    @close="onClose"
    size="max-w-6xl"
  >
    <div class="mb-5 flex justify-between">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Barang</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Data barang dari order yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" />
      </div>
    </div>
    <div class="mb-5 border-t border-gray-200 pt-5">
      <div class="flex justify-end gap-x-2">
        <div class="flex items-center">
          <ToggleButton
            :labels="{
              checked: 'QR Kurang',
              unchecked: 'QR Tambah',
            }"
            :width="96"
            color="#dc2626"
            v-model="qrScan.isDecrementQty"
          />
        </div>
        <base-search placeholder="Cari Kode" v-model="search" />
      </div>
      <div class="grid gap-x-4">
        <div :class="['my-2 flex sm:-mx-6 lg:-mx-8']">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable :footer="false">
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                    <th
                      scope="col"
                      class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Jumlah
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Produk
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody v-if="orderDetails.data.length > 0">
                  <tr
                    class="cursor-pointer"
                    v-for="(orderDetail, index) in orderDetails.data"
                    :key="orderDetail.id"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-6 text-sm font-medium text-gray-900"
                    >
                      <input
                        :checked="selectedProducts[index].qty"
                        tabindex="-1"
                        aria-describedby="cetak-faktur"
                        name="cetak-faktur"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </td>
                    <td
                      class="whitespace-nowrap py-4 pl-6 text-sm font-medium text-gray-900"
                    >
                      <svg
                        v-if="
                          getOrderDetailProduct(orderDetail).attributes
                            .qrcode &&
                          getOrderDetailProduct(orderDetail).attributes
                            .is_qrcode_active
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-4 w-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                        />
                      </svg>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      {{ orderDetail.attributes.product_code }} {{ orderDetail.type === 'order-detail-bonuses' ? '(BONUS)' : '' }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ orderDetail.attributes.product_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <input
                        v-model="selectedProducts[index].qty"
                        type="text"
                        name="jumlah"
                        class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan jumlah..."
                        :disabled="
                          getOrderDetailProduct(orderDetail).attributes
                            .qrcode &&
                          getOrderDetailProduct(orderDetail).attributes
                            .is_qrcode_active
                        "
                      />
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ orderDetail.attributes.product_qty }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900"
                      colspan="6"
                    >
                      <div
                        class="my-4 flex items-center justify-center space-y-2"
                      >
                        <button type="button">
                          <div class="mb-2 flex justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                          </div>
                          <p
                            class="text-center text-xs font-normal text-gray-400"
                          >
                            Tidak ada data barang
                            <br />
                            yang belum dikemas
                          </p>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end space-x-2">
      <button
        @click="onConfirm"
        type="button"
        class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
      >
        Konfirmasi
      </button>
      <button
        @click="onClose"
        type="button"
        class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
      >
        Kembali
      </button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseSearch from '@/components/base/Search.vue';
import Datatable from '@/components/base/Datatable';
import { ToggleButton } from 'vue-js-toggle-button';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
    BaseSearch,
    Datatable,
    ToggleButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    withScanQrCode: {
      type: Boolean,
      default: false,
    },
    orderDetails: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'input'],
  data() {
    return {
      selectedProducts: [],
      search: null,
      qrScan: {
        isDecrementQty: false,
      },
    };
  },
  watch: {
    visible(value) {
      this.qrScan.isDecrementQty = false;

      if (value) {
        this.initPacketProducts();
      }
    },
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      const selectedProducts = this.selectedProducts.filter(
        (product) => !!product.qty && Number(product.qty) > 0
      );
      const packetProducts = selectedProducts.map((selectedProduct) => {
        const orderDetail = this.orderDetails.data.find(
          (orderDetail) => orderDetail.id === selectedProduct.orderDetailOrBonusId
        );

        return {
          orderDetailOrBonusId: selectedProduct.orderDetailOrBonusId,
          type: selectedProduct.type,
          productId: selectedProduct.productId,
          qty: Math.min(
            Number(selectedProduct.qty),
            Number(orderDetail.attributes.product_qty)
          ),
        };
      });

      this.$emit('confirm', packetProducts);
    },
    onQrScanned(e) {
      try {
        const { text: qrCode } = e.detail;

        const orderDetail = this.orderDetails.data.find((orderDetail) => {
          const product = this.getOrderDetailProduct(orderDetail);

          return (
            product.attributes.qrcode === qrCode &&
            product.attributes.is_qrcode_active
          );
        });

        if (!orderDetail) {
          throw new Error('Produk tidak ada');
        }

        const selectedProductIndex = this.selectedProducts.findIndex(
          (product) => product.orderDetailOrBonusId === orderDetail.id
        );
        const selectedProduct = this.selectedProducts[selectedProductIndex];

        if (this.qrScan.isDecrementQty) {
          if (selectedProduct.qty < 1) {
            throw new Error('Produk terlalu sedikit');
          }
        } else if (selectedProduct.qty >= orderDetail.attributes.product_qty) {
          throw new Error('Produk melebihi batas');
        }

        if (this.qrScan.isDecrementQty) {
          if (selectedProduct.qty === 1) {
            this.selectedProducts[selectedProductIndex].qty = null;
          } else {
            this.selectedProducts[selectedProductIndex].qty--;
          }
        } else {
          this.selectedProducts[selectedProductIndex].qty++;
        }
      } catch (err) {
        // console.log(err)
      }
    },
    initPacketProducts() {
      this.selectedProducts = this.orderDetails.data.map((orderDetail) => ({
        orderDetailOrBonusId: orderDetail.id,
        type: orderDetail.type,
        productId: orderDetail.attributes.product_id,
        qty: null,
      }));
    },
    getOrderDetailProduct(orderDetail) {
      return this.getSingleIncluded(
        this.orderDetails,
        orderDetail.relationships.product.data.id
      );
    },
  },
  mounted() {
    document.addEventListener('barcode-scan', this.onQrScanned);
  },
};
</script>
