<template>
  <div class="mx-auto px-4 sm:px-4 lg:px-8">
    <BaseCard title="Pembelian ke Supplier">
      <template #action>
        <base-button to="pembelian/tambah-pembelian" type="button">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          Tambah PO
        </base-button>
      </template>
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <select
            v-model="is_completed"
            @change="onReload"
            class="focus:outline-none block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
          >
            <option :value="null">Semua</option>
            <option :value="true">Diterima</option>
            <option :value="false">Belum diterima</option>
          </select>
          <base-search v-model="search" @input="onReloadDebounce" />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full pt-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getOrders.meta.page.total"
              :perPage="getOrders.meta.page.perPage"
              :currentPage="getOrders.meta.page.currentPage"
              :meta="getOrders.meta"
              cursor
              :is-empty="!getOrders.data.length"
              @pagechanged="onPageChanged"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nomor Pembelian
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Produk
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Harga
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status Faktur
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      STATUS Laporan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      STATUS
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getOrders.data?.length > 0">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getOrders.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click.prevent="onViewOrder(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <order-code-column :value="data" />
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getOrderIncluded(data)?.attributes?.origin_code
                          | optional
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      <div class="text-sm text-gray-900">
                        {{
                          getOrderIncluded(data)?.attributes?.item_count
                            | toCurrency
                        }}
                      </div>
                      <div class="text-xs font-light text-gray-300">
                        {{
                          getOrderIncluded(data)?.attributes?.grand_total_weight
                            | toCurrency
                        }}
                        Kg
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        (getOrderIncluded(data)?.attributes?.final_price ?? 0)
                          | toCurrency
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        v-if="
                          getOrderIncluded(data)?.attributes
                            ?.is_valid_for_payment
                        "
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Valid
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Draft
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        v-if="getOrderIncluded(data)?.attributes?.is_completed"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Selesai
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum Selesai
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <base-badge color="indigo">
                        {{ getOrderIncluded(data)?.attributes?.order_status }}
                      </base-badge>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <!-- Detail Modal -->
        <SupplierOrderModal
          :visible="openDetail"
          with-stock-movement
          @close="openDetail = !openDetail"
          @refresh="onRefresh"
          @detail-stock-movement="onViewStockMovement"
        />

        <stock-movement-modal
          :visible="openDetailStockMovement"
          description="Data penerimaan barang dari Supplier"
          :with-dispute="false"
          :with-confirm="false"
          :with-send="false"
          force-display-first-packet
          @close="onCloseViewStockMovement"
        >
          <template #origin-office="{ originOffice }">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nomor Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ originOffice.attributes.code }}
              </dd>
            </div>
          </template>
        </stock-movement-modal>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import SupplierOrderModal from '@/components/supplier/SupplierOrderModal.vue';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import OrderCodeColumn from '@/components/order/order-code-column.vue';
import BaseSearch from '@/components/base/Search.vue';
import { orderRead } from '@/mixins/order/order-read.mixin';

export default {
  name: 'Home',
  components: {
    Datatable,
    SupplierOrderModal,
    StockMovementModal,
    OrderCodeColumn,
    BaseSearch,
  },
  mixins: [orderRead],
  data() {
    return {
      search: null,
      is_completed: false,
      openDetail: false,
      openDetailStockMovement: false,
    };
  },
  created() {
    this.onCreated();

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoadingOrder: 'orders/getLoading',
      isLoadingStockMovement: 'stock_movements/getLoading',
    }),
    isLoading() {
      return this.isLoadingOrder || this.isLoadingStockMovement;
    },
    readOrdersParams() {
      return {
        'filter[destination_office_category_id]': 1,
        'filter[destination_warehouse_id]':
          StorageService.getUser().current_warehouse,
        ...(!this.isNull(this.is_completed)
          ? this.is_completed
            ? { 'filter[order_status]': 'Selesai' }
            : { 'filter[not-order_status]': 'Selesai' }
          : {}),
        ...(this.search ? { 'filter[search]': this.search } : {}),
        include: 'order',
        'fields[simple-orders]': 'origin_code,destination_code,order',
        'fields[orders]':
          'origin_code,destination_code,updatedAt,item_count,grand_total_weight,final_price,is_valid_for_payment,is_completed,order_status,updatedAt',
        'page[limit]': 5,
        'pagination[type]': 'cursor',
      };
    },
    viewOrderParams() {
      return {
        'fields[orders]':
          'destination_code,origin-office,created_by,updatedAt,order-details,destination-office,has_payment_methods,deletedAt,is_valid_for_payment,grand_total_price,grand_total_weight,area_name,area_code',
        'fields[offices]':
          'code,name,address,address2,kta,address_details,office_type',
        'fields[order-details]':
          'product_code,product_qty,product_price,product_weight,total_price,total_weight,product_type',
        include: 'origin-office,order-details,destination-office',
      };
    },
  },
  methods: {
    ...mapActions({
      fetchStockMovementsByOrder: 'stock_movements/fetchStockMovementsByOrder',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    onOrderViewed() {
      this.fetchStockMovementsByOrder({
        orderId: this.getOrder.data.id,
      }).then(() => {
        this.openDetail = !this.openDetail;
      });
    },
    async onViewStockMovement(id) {
      const res = await this.fetchStockMovement({ id });

      if (res) {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      }
    },
    onCloseViewStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = true;
    },
    async onRefresh() {
      await this.onReload();

      this.openDetail = false;
    },
  },
};
</script>
