<template>
  <BaseCard title="Sisa Barang" description="Sisa barang yang belum dikemas">
    <Datatable :paginated="false" :footer="false">
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              KODE BARANG
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              NAMA BARANG
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              JUMLAH
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="orderDetailsRemainder.data.length > 0">
          <tr
            class="bg-white"
            v-for="(orderDetail, index) in orderDetailsRemainder.data"
            :key="index"
          >
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
            >
              {{ orderDetail.attributes.product_code }} {{ orderDetail.type === 'order-detail-bonuses' ? '(BONUS)' : '' }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ orderDetail.attributes.product_name }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ orderDetail.attributes.product_qty }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="bg-white">
            <td
              class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900"
              colspan="3"
            >
              <div class="my-4 flex items-center justify-center space-y-2">
                <button type="button">
                  <div class="mb-2 flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>
                  </div>
                  <p class="text-center text-xs font-normal text-gray-400">
                    Tidak ada data barang <br />
                    yang belum dikemas
                  </p>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>
  </BaseCard>
</template>

<script>
import Datatable from '@/components/base/Datatable';

export default {
  name: 'StockRemainderTable',
  props: {
    orderDetailsRemainder: {
      type: Object,
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    Datatable,
  },
  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
