<template>
  <base-card class="space-y-5">
    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-lg font-bold leading-6 text-gray-900">Barang</h3>
      <p class="mt-2 max-w-4xl text-sm text-gray-500">
        Tambah informasi stock.
      </p>
    </div>
    <Datatable :paginated="false" :footer="false">
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="item in [
                {
                  label: 'KODE BARANG',
                },
                {
                  label: 'NAMA BARANG',
                },
                {
                  label: 'STOCK',
                },
                {
                  label: 'JUMLAH',
                  classes: 'text-right',
                },
                {
                  label: 'AKSI',
                },
                {
                  label: 'TOTAL BERAT',
                  classes: 'text-right',
                },
                {
                  label: 'PV',
                  classes: 'text-right',
                },
                {
                  label: 'BV',
                  classes: 'text-right',
                },
              ]"
              scope="col"
              :key="item.label"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody>
          <tr
            class="bg-white"
            v-for="(stock, index) in returDetails"
            :key="index"
          >
            <td
              class="relative flex items-center whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
            >
              <input
                v-model="stock.product_code"
                @change="onChangeProductCode(index)"
                type="text"
                name="kode_barang"
                :id="'kode_barang_' + index"
                ref="kode_barang"
                class="block w-32 border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Kode barang"
                :disabled="disabled ? disabled : null"
              />
              <div
                v-if="!disabled"
                class="absolute inset-y-0 top-0 right-0 ml-3 flex items-center"
              >
                <base-button
                  type="button"
                  tabindex="-1"
                  for-icon
                  class="hover:text-gray-700"
                  @click="onOpenStockModal(index)"
                >
                  <Icon
                    class="h-5 w-5 text-gray-500"
                    icon="heroicons:magnifying-glass-circle-20-solid"
                  />
                </base-button>
              </div>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ stock.product_name }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ stock.current_stock | toCurrency }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <template v-if="stock.product_name">
                <input
                  @change="(e) => onChangeProductQty(index, e)"
                  type="text"
                  :id="'product_qty_' + index"
                  name="product_qty"
                  v-model="stock.product_qty"
                  ref="product_qty"
                  placeholder="Jumlah"
                  class="block w-full border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  :disabled="disabled ? disabled : null"
                />
              </template>
            </td>
            <td
              class="flex justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            >
              <!-- aksi -->
              <template
                v-if="!disabled && stock.product_name && stock.product_code"
              >
                <base-button
                  @click="onRemoveStock(index)"
                  class="text-red-300 hover:text-red-600"
                  tabindex="-1"
                  for-icon
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </base-button>
              </template>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <template v-if="stock.product_name">
                {{ stock.product_weight * stock.product_qty }}
              </template>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <template v-if="stock.product_name">
                {{ stock.point_value | toCurrency }}
              </template>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <template v-if="stock.product_name">
                {{ stock.bonus_value | toCurrency }}
              </template>
            </td>
          </tr>
        </tbody>
        <tfoot class="bg-gray-50" v-if="returDetails.length > 1">
          <tr>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
            >
              Total
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
            >
              {{ grandTotalPrice | toCurrency }}
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
          </tr>
          <tr>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
            >
              Total Berat
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
            >
              {{ grandTotalWeight | toCurrency }} Kg
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
          </tr>
          <tr>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
            >
              Total PV
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
            >
              {{ grandTotalPv | toCurrency }}
            </td>
            <td
              class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
          </tr>
          <tr>
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500"
            >
              Total BV
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-500"
            >
              {{ grandTotalBv | toCurrency }}
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-500"
              colspan="5"
            ></td>
          </tr>
        </tfoot>
      </template>
    </Datatable>

    <stock-table-modal
      :visible="stockModal.visible"
      :params="{
        warehouseId: retur.originWarehouse.id,
        'filter[without_price]': true,
        'fields[stocks]':
          'product_code,product_name,orderable_stock,stock_weight,product,is_orderable',
        'fields[products]': 'point,bonus',
        include: 'product',
      }"
      :columns="[]"
      @close="stockModal.visible = false"
      @click-product="onSelectStock"
    />
  </base-card>
</template>
<script>
import Datatable from '@/components/base/Datatable';
import BaseButton from '@/components/base/BaseButton';
import StockTableModal from '@/components/stock/StockTableModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { sum } from '@/utils/array';

export default {
  name: 'OrderDetailForm',
  components: {
    Datatable,
    BaseButton,
    StockTableModal,
  },
  props: {
    retur: Object,
    value: Array,
  },
  emits: ['input', 'retur-created'],
  data() {
    return {
      stockModal: {
        visible: false,
        index: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getRetur: 'returs/getRetur',
    }),
    returDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isEdit() {
      return this.retur.id;
    },
    disabled() {
      return this.retur.attributes.is_valid_for_packing;
    },
    grandTotalPrice() {
      return sum(this.returDetails, (item) => item.product_qty);
    },
    grandTotalWeight() {
      return sum(
        this.returDetails,
        (item) => item.product_weight * item.product_qty
      );
    },
    grandTotalPv() {
      return sum(this.returDetails, (item) => item.point_value);
    },
    grandTotalBv() {
      return sum(this.returDetails, (item) => item.bonus_value);
    },
  },
  methods: {
    ...mapActions({
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      createRetur: 'returs/createRetur',
      createReturDetail: 'retur_details/createReturDetail',
      patchReturDetail: 'retur_details/updateReturDetail',
      deleteReturDetail: 'retur_details/deleteReturDetail',
      createAlert: 'alert/createAlert',
    }),
    onOpenStockModal(index) {
      this.stockModal.visible = true;
      this.stockModal.index = index;
    },
    onSelectStock(data) {
      if (this.getStockOrderableStock(data) > 0) {
        if (
          this.checkStockIsExists(
            this.stockModal.index,
            data.attributes.product_code
          )
        ) {
          this.createErrorAlert('Barang Sudah Ditambahkan');
        } else {
          this.returDetails[this.stockModal.index].product_code =
            data.attributes.product_code;

          this.onChangeProductCode(this.stockModal.index);

          this.stockModal.visible = false;
        }
      }
    },
    async onChangeProductCode(index) {
      if (this.returDetails[index].product_code) {
        if (
          this.checkStockIsExists(index, this.returDetails[index].product_code)
        ) {
          this.createErrorAlert('Barang Sudah Ditambahkan');

          this.returDetails[index].product_code =
            this.returDetails[index].original_product_code;
        } else {
          const returDetailId = this.returDetails[index].id;

          const stock = await this.getStockByProductCode(
            this.returDetails[index].product_code
          );

          if (stock) {
            this.returDetails[index].original_product_code =
              this.returDetails[index].product_code;

            this.returDetails[index].product_name =
              stock.attributes.product_name;
            this.returDetails[index].current_stock =
              stock.attributes.orderable_stock;
            this.returDetails[index].product_weight =
              stock.attributes.stock_weight;
            this.returDetails[index].point_value = stock.attributes.bonus_point;
            this.returDetails[index].bonus_value = stock.attributes.bonus_value;

            const product = this.getSingleIncluded(
              this.getWarehouseStock,
              stock.relationships.product.data.id
            );

            this.returDetails[index].product_id = product.id;

            const returDetail = await (returDetailId
              ? this.updateReturDetail({
                  returDetailId,
                  productId: product.id,
                  productQty: 0,
                })
              : this.storeReturDetail({
                  productId: product.id,
                  productQty: 0,
                }));

            if (!returDetail) {
              this.createErrorAlert('Barang Tidak Ditemukan');
            } else {
              this.setReturDetail(index, returDetail.data.data, {
                pushEmptyProduct: false,
                focusProductQty: true,
                withProductQty: true,
              });
            }
          } else {
            this.createErrorAlert('Barang Tidak Ditemukan');

            this.returDetails[index].product_code =
              this.returDetails[index].original_product_code;
          }
        }
      }
    },
    async onChangeProductQty(index, event) {
      const returDetail = await this.updateReturDetail({
        returDetailId: this.returDetails[index].id,
        productId: this.returDetails[index].product_id,
        productQty: event.target.value,
      });

      if (!returDetail) {
        this.createErrorAlert('Jumlah melebihi batas');

        this.returDetails[index].product_qty =
          this.returDetails[index].original_product_qty;
      } else {
        this.setReturDetail(index, returDetail.data.data, {
          pushEmptyProduct: false,
          focusProductQty: false,
          withProductQty: false,
        });

        this.returDetails[index].original_product_qty =
          this.returDetails[index].product_qty;

        const isLastRow = this.returDetails.length - 1 === index;

        if (isLastRow) {
          this.pushEmptyProduct();
        }
      }
    },
    async onRemoveStock(index) {
      const returDetailId = this.returDetails[index].id;

      this.returDetails.splice(index, 1);

      await this.deleteReturDetail({ id: returDetailId });

      if (!this.returDetails.length) {
        this.pushEmptyProduct();
      }
    },
    pushEmptyProduct() {
      this.returDetails.push({
        id: null,
        product_id: null,
        product_code: null,
        product_name: null,
        current_stock: null,
        product_qty: null,
        original_product_qty: null,
        original_product_code: null,
        product_weight: null,
        point_value: null,
        bonus_value: null,
      });
    },
    checkStockIsExists(currentIndex, code) {
      return this.returDetails.filter(
        (returDetail, index) =>
          index !== currentIndex && returDetail.product_code === code
      ).length;
    },
    async getStockByProductCode(code) {
      const stocks = await this.fetchWarehouseStock({
        warehouseId: this.retur.originWarehouse.id,
        productCode: code,
        'filter[without_price]': true,
        'fields[stocks]':
          'product_code,product_name,orderable_stock,stock_weight,product,is_orderable,bonus_point,bonus_value',
        'fields[products]': 'code',
        include: 'product',
        'filter[has_stock_real]': true,
      });

      if (stocks?.data?.data?.length) {
        return stocks.data.data[0];
      } else {
        this.createErrorAlert('Barang Tidak Ditemukan');
      }
    },
    getReturDetailProduct: function (code) {
      if (!this.getRetur.data.relationships['details'].data.length) {
        return null;
      }

      return this.getIncludedByType(this.getRetur, 'details').find(
        (stock) => stock.attributes.product_code === code
      );
    },
    getStockOrderableStock(stock) {
      if (!this.isEdit) {
        return stock.attributes.orderable_stock;
      }

      const stockProductInOrderDetails = this.getReturDetailProduct(
        stock.attributes.product_code
      );

      if (!stockProductInOrderDetails) {
        return stock.attributes.orderable_stock;
      }

      return (
        stock.attributes.orderable_stock +
        stockProductInOrderDetails.attributes.product_qty
      );
    },
    setReturDetail(index, item, options = {}) {
      this.returDetails[index].id = item.id;

      if (options.withProductQty) {
        this.returDetails[index].product_qty = null;
      }

      if (options.focusProductQty) {
        setTimeout(() => {
          document.getElementById('product_qty_' + index).focus();
        }, 0);
      }
    },
    createErrorAlert(data) {
      this.createAlert({
        status: 'error',
        data,
      });
    },
    createSuccessAlert(data) {
      this.createAlert({
        status: 'success',
        data,
      });
    },
    async storeRetur() {
      const payload = {
        data: {
          type: 'returs',
          attributes: {
            shipment_type: this.retur.attributes.shipment_type,
            is_using_stocks: this.retur.attributes.is_using_stocks,
          },
          relationships: {
            'origin-office': {
              data: {
                type: 'offices',
                id: this.retur.originOffice.id,
              },
            },
          },
        },
      };

      const retur = await this.createRetur({
        payload,
        params: {
          include: 'details',
        },
      });

      this.$emit('retur-created', retur.data.data);
    },
    async storeReturDetail({ productId, productQty }) {
      if (!this.retur.id) {
        await this.storeRetur();

        this.createSuccessAlert(`Faktur Retur Tersimpan`);
      }

      return await this.createReturDetail({
        payload: {
          data: {
            type: 'retur-details',
            attributes: {
              product_qty: productQty ? parseInt(productQty) : 0,
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
              retur: {
                data: {
                  type: 'returs',
                  id: this.getRetur.data.id,
                },
              },
            },
          },
        },
      });
    },
    async updateReturDetail({ returDetailId, productId, productQty }) {
      return await this.patchReturDetail({
        id: returDetailId,
        payload: {
          data: {
            type: 'retur-details',
            id: returDetailId,
            attributes: {
              product_qty: productQty ? parseInt(productQty) : 0,
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
