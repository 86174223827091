<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <OrderCreateForm
      destination-office-type="supplier"
      :area="selectedArea"
      v-model="order"
      v-on:change-address="setupArea"
      v-on:change-order-shipment="setupArea"
      v-on:change-destination="setupArea"
    />
    <OrderDetailTable
      v-if="order.relationships.destinationOffice.id"
      view-as="supplier"
      :columns="['product_type']"
      :create-order-payload="{
        order_type: 'purchase',
        order_shipment: order.attributes.order_shipment,
        notes: order.attributes.notes,
        is_ppn_included: true,
        is_pre_order: false,
      }"
      :origin-warehouse="{
        id: order.relationships.destinationWarehouse.id,
      }"
      :destination-warehouse="{
        id: StorageService.getUser().current_warehouse,
      }"
      :destination-office="{
        id: StorageService.getUser().office_id,
        code: order.relationships.originOffice.attributes.code,
      }"
      :origin-office="{
        id:
          order.relationships.destinationOffice.attributes.office_id ??
          order.relationships.destinationOffice.id,
        code: rawDestinationOfficeCode,
      }"
      :buyer-type="{
        id: getBuyerTypeByName('Pusat').id,
        code: order.relationships.buyerType.attributes.code,
      }"
      :area="{
        id: getAreaByCode(0).id,
        code: order.relationships.area.attributes.code,
      }"
      :order-id="order.id"
      :min-grand-total="0"
      origin-office-category-type="Supplier"
      v-model="orderDetails"
      v-on:order-created="handleCreatedOrder"
      v-on:created="handleCreatedOrderDetail"
    />

    <div
      v-if="dataOrders.nama_supplier"
      class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
    >
      <div class="flex flex-col gap-y-4">
        <div class="flex flex-row gap-x-4">
          <div class="field-inset-default w-full bg-gray-100">
            <label
              for="totalbarang"
              class="block text-xs font-bold text-gray-700"
            >
              Total Barang
            </label>
            <input
              v-model="dataOrders.total_barang"
              type="text"
              name="totalbarang"
              id="totalbarang"
              class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="0"
              disabled
            />
          </div>
          <div class="field-inset-default w-full bg-gray-100">
            <label
              for="totalbarang"
              class="block text-xs font-bold text-gray-700"
            >
              Jumlah Harga
            </label>
            <input
              v-model="dataOrders.jumlah_harga"
              v-currency
              type="text"
              name="totalbarang"
              id="totalbarang"
              class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="0"
              disabled
            />
          </div>
        </div>
        <div class="ml-4 flex flex-row justify-end">
          <div class="field-inset-default w-1/2 bg-gray-100">
            <label for="ppn" class="block text-xs font-bold text-gray-700">
              PPN (%)
            </label>
            <input
              v-model="dataOrders.ppn"
              @blur="changePPN"
              name="ppn"
              id="ppn"
              class="input-inset-select bg-gray-100"
              placeholder="0"
              disabled
            />
          </div>
        </div>
        <div class="ml-4 flex flex-row justify-end">
          <div class="field-inset-default w-1/2 bg-gray-100">
            <label for="grandtot" class="block text-xs font-bold text-gray-700">
              Grand Total
            </label>
            <input
              v-model="dataOrders.grandtotal"
              name="grandtotal"
              id="grandtotal"
              class="input-inset-select bg-gray-100"
              placeholder="0"
              disabled
              v-currency
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-full justify-end space-x-2 pt-5">
      <base-button
        v-if="!getOrder.data.attributes.is_valid_for_payment"
        :disabled="!saveable"
        :class="saveable ? '' : 'border-gray-300 bg-gray-300 '"
        @click="handleSave"
      >
        Simpan
      </base-button>
      <base-button color="white" to="/gudang/pembelian"> Kembali </base-button>
    </div>

    <transition
      name="slide"
      enter-active-class="slideInDown"
      leave-active-class="slideOutTop"
    >
      <div class="fixed top-0 right-0 z-50 mt-4 w-full" v-if="alert">
        <Alert @alert-action="alert = !alert" class="mx-6"></Alert>
      </div>
    </transition>

    <!-- Detail Modal -->
    <SupplierOrderModal :visible="openDetail" redirect-on-close />
    <!-- 
    <ProductModal
      @change="changeProduct"
      :visible="visibleProductModal"
      @close="() => visibleProductModal = false"
    /> -->
    <!-- Loader -->
    <loading
      v-if="
        isLoading || isProductLoading || isOrderLoading || isSupplierLoading
      "
    ></loading>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import Alert from '@/components/base/Alert.vue';
import { StorageService } from '@/services/storage.service';
import SupplierOrderModal from '@/components/supplier/SupplierOrderModal.vue';
import OrderCreateForm from '@/components/order/create/OrderCreateForm.vue';
import { orderCreateMixin } from '@/mixins/order/order-create.mixin';
// import ProductModal from '@/components/order/create/ProductModal.vue';
import { orderDetailsFormMixin } from '@/mixins/order/order-details-form.mixin';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import OrderDetailTable from '@/components/order/create/OrderDetailTable.vue';

export default {
  name: 'TambahPembelianSupplier',
  components: {
    Alert,
    SupplierOrderModal,
    OrderCreateForm,
    // ProductModal,
    OrderDetailTable,
  },
  mixins: [orderCreateMixin, orderDetailsFormMixin, paymentMethodFormMixin],
  data() {
    return {
      StorageService,
      orderId: null,
      currentIndex: null,
      loading: {
        visible: false,
        text: null,
      },
      is_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      isSubmitted: false,
      visibleProductModal: false,
      productIndex: 0,
      search: null,
      productKeyword: '',
      alert: false,
      listBuyerTypes: [],
      listAreas: [],
      ppn_percentage: null,
      origin_warehouse_id: null,
      destination_warehouse_id: null,
      destination_office_id: null,
      dataPembelian: {
        data: { id: '' },
      },
      dataOrders: {
        id: null,
        nomor_po: null,
        tanggal_po: dayjs().format('YYYY-MM-DD'),
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        is_ppn: false,
        barang: [
          {
            kode_barang: null,
            nama_barang: null,
            jumlah: null,
            harga_supp: null,
            total_harga: null,
          },
        ],
        total_barang: 0,
        jumlah_harga: 0,
        ppn: 0,
        grandtotal: 0,
      },
      metodePembayaran: [
        {
          officeBank: null,
          jenisPembayaran: null,
          nominalPembayaran: null,
        },
      ],
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'suppliers/getLoading',
      isProductLoading: 'products/getLoading',
      isOrderLoading: 'orders/getLoading',
      getSuppliers: 'simple_offices/getSimpleOffices',
      getProductsByOffice: 'products/getProductsByOffice',
      getError: 'purchaseorders/getError',
      getSetups: 'setups/getSetups',
      getBanksByOffice: 'banks/getBanksByOffice',
      getOrder: 'orders/getOrder',
      getOrders: 'orders/getOrders',
      isSupplierLoading: 'simple_offices/getLoading',
    }),
    saveable() {
      return true;
    },
    orderParams() {
      return {
        include: 'destination-office,origin-office,order-details',
        // fields: {
        //   orders:
        //     'createdAt,origin_code,created_by,destination_code,final_price,is_completed,item_count,is_valid_for_payment,final_price,grand_total_weight,destination-office,origin-office,order-details,order_status',
        //   offices: 'code,name,address,address2',
        //   'order-details':
        //     'product_code,product_qty,product_weight,product_weight,total_price,total_weight',
        // },
      };
    },
  },
  created() {
    this.orderId = this.$route.params.id;
    this.setupPaymentMethodTypes({
      officeId: StorageService.getUser().office_id,
    });
    this.fetchBanksByOffice({
      office_id: StorageService.getUser().office_id,
    }).then((res) => {
      const officeBankType = new Set([
        ...res.data.data.map(
          (officeBank) => officeBank.attributes.office_bank_type
        ),
      ]);

      this.paymentMethods = this.paymentMethods.filter((method) => {
        return [...officeBankType].includes(method.value);
      });
      this.officeBanks = res.data.data;
    });

    if (this.$route.params.id) {
      this.setupOrder(this.$route.params.id).then(() => {
        this.orderId = this.order.id;
        this.setOrderDetails();
        this.setPaymentDetails();
        this.setupPaymentMethods();
      });
    }
    this.fetchSetups();
    this.destination_office_id = StorageService.getUser().office_id;
    this.destination_warehouse_id = StorageService.getUser().current_warehouse;
    this.fetchBuyerTypes({
      keyword: null,
    }).then((response) => {
      this.listBuyerTypes = response.data.data;
    });
    this.fetchAreas({
      keyword: null,
    }).then((response) => {
      this.listAreas = response.data.data;
    });
    const handleEscape = () => {
      this.success = this.failed;
    };
    this.setEscape(handleEscape);
  },
  watch: {
    dataOrders: {
      handler() {
        if (!this.isSubmitted) {
          localStorage.setItem('orders.purchase', JSON.stringify(this.$data));
        }
      },
      deep: true,
    },
  },
  methods: {
    // aksiSimpan(){
    //   this.success = !this.success
    // }
    ...mapActions({
      createAlert: 'alert/createAlert',
      fetchSetups: 'setups/fetchSetups',
      fetchSuppliers: 'simple_offices/fetchSimpleOffices',
      fetchProductsByOffice: 'products/fetchProductsByOffice',
      createOrder: 'orders/createOrder',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchAreas: 'areas/fetchAreas',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchOffices: 'offices/fetchOffices',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchOrder: 'orders/fetchOrder',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchOrders: 'orders/fetchOrders',
      postOrderDetail: 'order_details/createOrderDetail',
      patchOrderDetail: 'order_details/updateOrderDetail',
      deleteOrderDetail: 'order_details/deleteOrderDetail',
      updateOrder: 'orders/updateOrder',
    }),
    dayjs,
    async loadSupplier(params = {}) {
      return await this.fetchSuppliers({
        'filter[office_category_id]': 6,
        'filter[search]': this.search,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'office',
        'fields[offices]': 'code,name,address,phone',
        ...params,
      });
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    handleCreatedOrderDetail() {
      this.pushEmptyPaymentDetailsItem();
    },
    debounceSearch: debounce(function () {
      this.loadSupplier();
    }, 300),
    handleCreatedOrder(order) {
      this.order.id = order.id;
      this.order.attributes.is_valid_for_payment = false;

      this.$router.push(`/gudang/pembelian/${order.id}`);
    },
    handleSave() {
      // this.setLoading('Menyimpan Order');

      this.validate({ id: this.order.id }).then((response) => {
        if (response) {
          // this.setLoading('Loading Order');

          this.$router.push('/gudang/pembelian');
        }
      });
    },
    onPageChangeProducts(page) {
      this.fetchProductsByOffice({
        include: 'product-limits,unit,product-category',
        office_id: this.dataOrders.id_supplier,
        pageNumber: page,
        pageSize: 5,
      });
    },
    startLoading(text) {
      this.loading.text = text;
      this.loading.visible = true;
    },
    stopLoading() {
      this.loading.visible = false;
    },
    handleChangeMethod(index) {
      this.metodePembayaran[index].officeBank = null;

      if (this.metodePembayaran[index].jenisPembayaran) {
        this.fetchBanksByOffice({
          office_id: this.dataOrders.id_supplier,
          type: this.metodePembayaran[index].jenisPembayaran.value,
          isActive: true,
        });
      }
    },
    async handleSearchProduct(index) {
      this.currentIndex = index;

      const productCode = this.orderDetails[index].search;

      if (productCode) {
        const stock = await this.searchStockByProduct({ productCode });

        if (stock) {
          this.handleChangeStock(stock);
        } else {
          this.orderDetails[this.currentIndex].search =
            this.orderDetails[this.currentIndex].product.code;
        }
      }
    },
    async handleChangeStock(stock) {
      const product = this.getSingleIncluded(
        this.getWarehouseStock,
        stock.relationships.product.data.id
      );

      const orderDetail = await this.storeOrderDetail({
        productId: product.id,
        orderDetailId: this.orderDetails[this.currentIndex].id,
        qty: 0,
      });

      if (orderDetail) {
        this.setOrderDetail(this.currentIndex, orderDetail);

        this.visibleStockModal = false;
        this.currentIndex = null;
      } else {
        this.orderDetails[this.currentIndex].search =
          this.orderDetails[this.currentIndex].product.code;
      }
    },
    searchPO() {
      if (this.dataOrders.nomor_po) {
        this.fetchOrders({
          destination_warehouse_id: StorageService.getUser().current_warehouse,
          search: this.dataOrders.nomor_po,
          is_completed: false,
        }).then(() => {
          if (this.getOrders.data.length > 0) {
            this.dataOrders.id = this.getOrders.data[0].id;
            this.dataOrders.nomor_po = this.getOrders.data[0].attributes.code;
            this.dataOrders.id_supplier = this.getRelationships(
              this.getOrders,
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).id;
            this.dataOrders.kode_supplier = this.getRelationships(
              this.getOrders,
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).attributes.code;
            this.dataOrders.nama_supplier = this.getRelationships(
              this.getOrders,
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).attributes.name;
            this.dataOrders.is_ppn =
              this.getOrders.data[0].attributes.is_ppn_included;
            this.getOrders.data[0].attributes.order_details.map(function (
              item,
              index
            ) {
              this.dataOrders.barang[index].kode_barang = item.product_code;
              this.dataOrders.barang[index].nama_barang = item.product_name;
              this.dataOrders.barang[index].jumlah = item.product_qty;
              this.dataOrders.barang[index].harga_supp = item.product_price;
              this.dataOrders.barang[index].total_harga =
                this.dataOrders.barang[index].harga_supp *
                this.dataOrders.barang[index].jumlah;
              const barang = {
                kode_barang: null,
                nama_barang: null,
                jumlah: null,
                harga_supp: null,
                total_harga: null,
              };
              this.dataOrders.barang.push(barang);
              this.dataOrders.total_barang++;
            },
            this);
            this.dataOrders.jumlah_harga =
              this.getOrders.data[0].attributes.final_price;
            this.origin_warehouse_id = this.getRelationships(
              this.getOrders,
              this.getOrders.data[0].relationships['origin-warehouse'].data.id
            ).id;
            this.destination_warehouse_id = this.getRelationships(
              this.getOrders,
              this.getOrders.data[0].relationships['destination-warehouse'].data
                .id
            ).id;
            this.destination_office_id =
              this.getOrders.data[0].relationships[
                'destination-office'
              ].data.id;
            this.changePPNPercentage();
          } else {
            this.dataOrders.id_supplier = null;
            this.dataOrders.kode_supplier = null;
            this.dataOrders.nama_supplier = null;
          }
        });
      }
    },
    changePPNPercentage() {
      if (this.dataOrders.is_ppn) {
        const value = parseInt(
          this.getPPNPercentage('ppn').attributes.setup_value
        );
        this.ppn_percentage = value;
        this.dataOrders.ppn = parseFloat(
          (this.ppn_percentage * this.dataOrders.jumlah_harga) / 100
        );
        this.dataOrders.grandtotal =
          this.dataOrders.jumlah_harga + this.dataOrders.ppn;
      } else {
        this.dataOrders.ppn = 0;
        this.dataOrders.grandtotal =
          this.dataOrders.jumlah_harga + this.dataOrders.ppn;
        this.ppn_percentage = null;
      }
    },
    async handleChangeQty(index) {
      if (
        this.orderDetails[index].qty < 0 ||
        isNaN(this.orderDetails[index].qty)
      ) {
        this.orderDetails[index].qty = 0;

        this.createAlert({
          data: 'Jumlah harus bilangan bulat dan tidak boleh kurang dari 0',
          type: 'error',
        });
      } else {
        const orderDetail = await this.storeOrderDetail({
          orderDetailId: this.orderDetails[index].id,
          productId: this.orderDetails[index].product.id,
          qty: this.orderDetails[index].qty,
        });

        if (!orderDetail) {
          this.orderDetails[index].qty = this.orderDetails[index].product.stock;
        } else {
          const isLastRow = this.orderDetails.length - 1 === index;

          if (isLastRow) {
            this.pushEmptyProduct();
          }
        }
      }
    },
    pushEmptyProduct() {
      this.orderDetails.push({
        id: null,
        search: null,
        product: {
          id: null,
          code: null,
          name: null,
          stock: null,
          status: null,
          isPoint: null,
          pointValue: null,
          bonusValue: null,
          price: null,
          weight: null,
        },
        qty: null,
        type: 'reguler',
      });
    },
    async storeOrderDetail({ orderDetailId, productId, qty }) {
      if (orderDetailId) {
        return await this.updateOrderDetail({ orderDetailId, productId, qty });
      } else {
        return await this.createOrderDetail({ productId });
      }
    },
    async setupPaymentMethods() {
      await this.fetchPaymentPaymentMethods({
        id: this.getOrder.data.relationships['payments'].data[0].id,
        include: 'office-bank',
      });

      this.setPaymentDetails();
    },
    changeTotal(index, event) {
      this.dataOrders.barang[index].total_harga =
        this.dataOrders.barang[index].jumlah *
        this.dataOrders.barang[index].harga_supp;
      const value = Number(
        event.target.value.split(',')[0].replace(/\D/gi, '')
      );

      this.dataOrders.barang[index].jumlah = value;
      event.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    addFormProduct() {
      this.dataOrders.jumlah_harga = this.dataOrders.barang.reduce(
        (a, b) => a + (b['total_harga'] || 0),
        0
      );
      if (this.ppn_percentage) {
        this.dataOrders.ppn = parseFloat(
          (this.ppn_percentage * this.dataOrders.jumlah_harga) / 100
        );
      }
      this.dataOrders.grandtotal =
        this.dataOrders.jumlah_harga + this.dataOrders.ppn;
      if (
        this.dataOrders.barang[this.dataOrders.barang.length - 1].kode_barang
      ) {
        this.dataOrders.jumlah_harga = this.dataOrders.barang.reduce(
          (a, b) => a + (b['total_harga'] || 0),
          0
        );
        this.dataOrders.grandtotal = this.dataOrders.jumlah_harga;
      }
    },
    changeDiscount() {
      this.dataOrders.netto =
        parseInt(this.dataOrders.netto) - parseInt(this.dataOrders.diskon);
      this.dataOrders.grandtotal =
        this.dataOrders.netto +
        (parseInt(this.dataOrders.ppn) * parseInt(this.dataOrders.netto)) / 100;
    },
    changePPN() {
      this.dataOrders.grandtotal =
        this.dataOrders.netto +
        (parseInt(this.dataOrders.ppn) * parseInt(this.dataOrders.netto)) / 100;
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_code: param.kode_barang,
          product_qty: parseFloat(param.jumlah),
          product_price: parseFloat(param.harga_supp),
        };
        if (data.product_code) dataDetail.push(data);
      });
      return dataDetail;
    },
    getPPNPercentage(setup_key) {
      let data = this.getSetups.data.filter(function (el) {
        return el.attributes.setup_key === setup_key;
      });
      return data[0];
    },
    getBuyerTypeByName(name) {
      let buyerType = this.listBuyerTypes.filter(function (el) {
        return el.attributes.name === name;
      });
      return buyerType[0];
    },
    getAreaByCode(code) {
      let area = this.listAreas.filter(function (el) {
        return el.attributes.code === code;
      });
      return area[0];
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    productExists(code) {
      const data = this.dataOrders.barang.filter(function (el) {
        return el.kode_barang === code;
      });
      if (data.length > 1) return false;
      else return true;
    },
    async handleRemoveProduct(index) {
      this.startLoading('Menghapus Barang');

      const orderDetailId = this.orderDetails[index].id;

      await this.deleteOrderDetail({ id: orderDetailId });

      this.orderDetails.splice(index, 1);

      if (!this.orderDetails.length) {
        this.pushEmptyProduct();
      }

      this.stopLoading();
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.$router.push('/gudang/pembelian');
          this.dataPembelian = response.data;
          this.openDetail = false;
          this.fetchOrders({
            destination_warehouse_id:
              StorageService.getUser().current_warehouse,
            is_completed: this.is_completed,
            ...this.orderParams,
          });
        }
      });
    },
    changeNominal(event, index) {
      const value = Number(
        event.target.value.split(',')[0].replace(/\D/gi, '')
      );

      this.metodePembayaran[index].nominalPembayaran = value;
    },
    clearData() {
      this.dataOrders = {
        id: null,
        nomor_po: null,
        tanggal_po: dayjs().format('YYYY-MM-DD'),
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        is_ppn: false,
        barang: [
          {
            kode_barang: null,
            nama_barang: null,
            jumlah: null,
            harga_supp: null,
            total_harga: null,
          },
        ],
        total_barang: 0,
        total_harga: 0,
        ppn: 0,
        grandtotal: 0,
      };
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSuppliers,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>
