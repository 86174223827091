<template>
  <transition name="fade">
    <BaseModal
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      colorheading="bg-green-50"
      colorbody="bg-green-50"
      size="max-w-xl"
      id="modal_confirmation"
    >
      <h3 class="text-center text-lg font-bold leading-6 text-gray-900">
        Konfirmasi Validasi Penjualan Ke Kasir
      </h3>
      <div
        class="relative flex content-center items-center justify-center py-2"
      >
        <svg
          class="text-green-100"
          width="194"
          height="191"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
            fill="currentColor"
          />
        </svg>
        <div class="absolute flex w-full justify-center">
          <DocumentSuccess class="h-32" />
        </div>
      </div>
      <p class="text-center text-sm leading-6 text-gray-900 mb-4" id="modal-title">
        Faktur akan divalidasi ke kasir. <br />
        Apakah Anda yakin data yang dibuat sudah benar?
      </p>
      <div>
        <button
          @click="saving"
          id="btn_confirm_penjualan"
          class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Siap, Validasi
        </button>
        <button
          @click="onClose"
          class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
        >
          Kembali
        </button>
      </div>
    </BaseModal>
  </transition>
</template>
<script>
import BaseModal from '@/components/base/BaseModal';
import { DocumentSuccess } from '@/assets/icons';

export default {
  components: {
    BaseModal,
    DocumentSuccess,
  },
  props: {
    visible: {
      required: true,
    }
  },
  name: 'ConfirmModal',
  methods: {
    onClose() {
      this.$emit('close');
    },
    saving() {
      this.$emit('saving');
    },
  },
};
</script>
