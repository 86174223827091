import dayjs from 'dayjs';
import { mapGetters, mapActions } from 'vuex';
import { requestMixin } from '@/mixins/request/request'

export function pengemasanMixin(
  { indexPath } = {
    indexPath: '/gudang/pengeluaran/barang-ke-stockist',
  }
) {
  return {
    mixins: [requestMixin],
    data() {
      return {
        packets: [],
        packetProductModal: {
          visible: false,
          index: 0,
        },
        visibleWarningRemainderModal: false,
        visibleConfirmationModal: false,
        orderDetailBonuses: {
          data: [],
          included: []
        }
      };
    },
    computed: {
      ...mapGetters({
        getOrderDetails: 'order_details/getOrderDetails',
        getOrder: 'orders/getOrder',
        getOrderLoading: 'orders/getLoading',
        getOrderDetailsLoading: 'order_details/getLoading',
        getStockMovementLoading: 'stock_movements/getLoading',
      }),
      orderDetailsRemainder() {
        const orderDetails = this.getOrderDetails.data
          .map((orderDetail) => ({
            id: orderDetail.id,
            type: orderDetail.type,
            relationships: orderDetail.relationships,
            attributes: {
              ...orderDetail.attributes,
              product_qty:
                this.calculateOrderDetailsProductRemainder(orderDetail),
            },
          }))
          .filter((orderDetail) => orderDetail.attributes.product_qty)
        const orderDetailBonuses = this.orderDetailBonuses.data
          .map((orderDetailBonus) => ({
            id: orderDetailBonus.id,
            type: orderDetailBonus.type,
            relationships: orderDetailBonus.relationships,
            attributes: {
              ...orderDetailBonus.attributes,
              product_qty:
                this.calculateOrderDetailsProductRemainder(orderDetailBonus),
            },
          }))
          .filter((orderDetailBonus) => orderDetailBonus.attributes.product_qty)

        return {
          data: [...orderDetails, ...orderDetailBonuses],
          included: [...this.getOrderDetails.included, ...this.orderDetailBonuses.included],
        };
      },
      sumPacketProductQty() {
        return this.packets.reduce(
          (total, packet) =>
            total +
            packet.products.reduce((total, product) => total + product.qty, 0),
          0
        );
      },
      currentStockMovement() {
        return this.getIncludedByType(this.getOrder, 'stock-movements').find(
          (stockMovement) => {
            return (
              stockMovement.attributes['movement-type'] === 'main-delivery' &&
              !stockMovement.attributes.is_shipped &&
              !stockMovement.attributes.is_received
            );
          }
        );
      },
      printStockMovementAttributes() {
        const destinationOffice = this.getSingleIncluded(
          this.getOrder,
          this.getOrder.data.relationships['destination-office'].data.id
        );

        return {
          code: this.currentStockMovement
            ? this.currentStockMovement.attributes.code
            : '-',
          date: this.currentStockMovement
            ? dayjs(this.currentStockMovement.attributes.updatedAt).format(
                'll LT'
              )
            : '-',
          order: {
            code: `${this.getOrder.data.attributes.origin_code}/${this.getOrder.data.attributes.destination_code}`,
            destinationOffice: destinationOffice?.attributes?.code ?? '-',
            address: destinationOffice?.attributes?.address ?? '-',
            phone: destinationOffice?.attributes?.phone ?? '-',
          },
          packets: this.packets.map((packet) => ({
            code: packet.code,
            name: packet.name,
            products: packet.products.map((product) => ({
              shipped_product_qty: product.qty,
              type: this.getOrderDetailOrBonusById(product.orderDetailOrBonusId).type,
              product_name: this.getOrderDetailOrBonusById(product.orderDetailOrBonusId)
                .attributes.product_name,
            })),
          })),
        };
      },
      printPacketAttributes() {
        return this.printStockMovementAttributes.packets[
          this.print.packetIndex
        ];
      },
    },
    methods: {
      ...mapActions({
        createStockMovement: 'stock_movements/createStockMovement',
        updateStockMovement: 'stock_movements/updateStockMovement',
        fetchOrder: 'orders/fetchOrder',
        fetchOrderDetailsByOrderId: 'order_details/fetchOrderDetailsByOrderId',
      }),
      // Events
      onConfirmPacketProduct(products) {
        const packetProducts =
          this.packets[this.packetProductModal.index].products;

        products.forEach((product) => {
          const productIndex = packetProducts.findIndex(
            (packetProduct) => packetProduct.productId === product.productId && packetProduct.type === product.type
          );

          if (productIndex < 0) {
            this.packets[this.packetProductModal.index].products.push({
              ...product,
            });
          } else {
            this.packets[this.packetProductModal.index].products[
              productIndex
            ].qty += product.qty;
          }
        });

        this.packetProductModal.visible = false;
      },
      onAddPacketProduct(index) {
        this.packetProductModal.visible = true;
        this.packetProductModal.index = index;
      },
      onAddPacket() {
        this.packets.push({
          code: `Koli ${this.packets.length + 1}`,
          name: `Koli ${this.packets.length + 1}`,
          products: [],
        });
      },
      onRemovePacket(index) {
        this.packets.splice(index, 1);
      },
      onBlurPacketProductQty(e, packetIndex, productIndex) {
        const value = Number(e.target.value);
        const product = this.packets[packetIndex].products[productIndex];
        const orderDetail = this.getOrderDetailOrBonusById(product.orderDetailOrBonusId);
        const remainderQty =
          this.calculateOrderDetailsProductRemainder(orderDetail) + product.qty;

        if (isNaN(value) || value < 1 || value > remainderQty) {
          e.target.value = product.qty;
        } else {
          this.packets[packetIndex].products[productIndex].qty = value;
        }
      },
      onRemovePacketProduct({ packetIndex, productIndex } = {}) {
        this.packets[packetIndex].products.splice(productIndex, 1);
      },
      onSubmit() {
        if (this.orderDetailsRemainder.data.length) {
          this.visibleWarningRemainderModal = true;
        } else {
          this.visibleConfirmationModal = true;
        }
      },
      async onConfirmPackets() {
        this.visibleConfirmationModal = false;
        this.visibleWarningRemainderModal = false;

        const payload = {
          data: {
            type: 'stock-movements',
            attributes: {
              origin_warehouse_id:
                this.getOrder.data.relationships['origin-warehouse'].data.id,
              destination_warehouse_id:
                this.getOrder.data.relationships['destination-warehouse'].data
                  .id,
              origin_office_id:
                this.getOrder.data.relationships['origin-office'].data.id,
              destination_office_id:
                this.getOrder.data.relationships['destination-office'].data.id,
              packets: this.packets.map((packet) => ({
                code: packet.code,
                name: packet.code,
                products: packet.products.map((product) => ({
                  product_id: product.productId,
                  is_bonus: product.type === 'order-detail-bonuses',
                  shipped_product_qty: product.qty,
                })),
              })),
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.getOrder.data.id,
                },
              },
            },
          },
        };

        let stockMovement = false;

        if (!this.currentStockMovement) {
          stockMovement = await this.createStockMovement(payload);
        } else {
          payload.data.id = this.currentStockMovement.id;

          stockMovement = await this.updateStockMovement(payload);
        }

        if (stockMovement) {
          await this.setOrder();

          setTimeout(() => this.printStockMovement(), 0);

          const waitForEl = setInterval(() => {
            const el = document.querySelector(
              'div.vue-html2pdf > section.pdf-preview > button'
            );

            if (el) {
              el.onclick = () => {
                this.$router.push(indexPath || this.indexPath);
              };
              clearInterval(waitForEl);
            }
          }, 100);
        }
      },

      // Print
      printStockMovement() {
        setTimeout(() => this.$refs.printStockMovement.generatePdf(), 0);
      },

      // Helpers
      getOrderDetailOrBonusById(id) {
        return [...this.getOrderDetails.data, ...this.orderDetailBonuses.data].find(
          (orderDetail) => orderDetail.id === id
        );
      },
      getOrderDetailByProductId(productId) {
        return this.getOrderDetails.data.find(
          (orderDetail) => orderDetail.attributes.product_id === productId
        );
      },
      getOrderDetailBonusByProductId(productId) {
        return this.orderDetailBonuses.data.find(
          (orderDetailBonus) => orderDetailBonus.attributes.product_id === productId
        );
      },
      calculateOrderDetailsProductRemainder(orderDetailOrBonus) {
        const totalPacketProductQty = this.packets.reduce((total, packet) => {
          const productMatched = packet.products.find(
            (product) => product.orderDetailOrBonusId === orderDetailOrBonus.id
          );

          if (!productMatched) {
            return total;
          }

          return total + productMatched.qty;
        }, 0);

        if (orderDetailOrBonus.type === 'order-detail-bonuses') {
          return orderDetailOrBonus.attributes.bonus_qty - totalPacketProductQty
        }

        // mengurangi jumlah yg di order-details, dengan jumlah di stock-movements packets
        // return orderDetailOrBonus.attributes.product_qty - totalPacketProductQty;
        return (
          orderDetailOrBonus.attributes.product_not_packed - totalPacketProductQty
        );
      },

      // Setter
      async setOrder() {
        await this.fetchOrder({
          id: this.$route.params.id,
          fields: {
            orders:
              'origin_code,destination_code,updatedAt,origin-office,destination-office,origin-warehouse,destination-warehouse,stock-movements,order_shipment,province,city,district,village,shipment_address',
            offices: 'code,name,address,address2,phone',
            warehouses: 'code',
          },
          include:
            'origin-office,destination-office,origin-warehouse,destination-warehouse,stock-movements,province,city,district,village',
        });
      },
      async setOrderDetails() {
        await this.fetchOrderDetailsByOrderId({
          orderId: this.getOrder.data.id,
          params: {
            include: 'product',
            'fields[order-details]':
              'product_id,product_code,product_name,product_qty,product,product_not_packed',
            'fields[products]': 'qrcode,is_qrcode_active',
          },
        });
      },
      async setOrderDetailBonuses() {
        const [res, error] = await this.request(`/api/v1/orders/${this.getOrder.data.id}/order-detail-bonuses`, {
          params: {
            include: 'product',
            'fields[order-detail-bonuses]':
              'product_id,product_code,product_name,bonus_qty,product',
            'fields[products]': 'qrcode,is_qrcode_active',
          }
        })

        if (!error) {
          this.orderDetailBonuses.data = res.data
          this.orderDetailBonuses.included = res?.included ?? []
        }
      },
      setPackets() {
        if (!this.currentStockMovement) {
          this.packets = [
            {
              code: 'Koli 1',
              name: 'Koli 1',
              products: [],
            },
          ];
        } else {
          this.packets = this.currentStockMovement.attributes.packets.map(
            (packet) => ({
              code: packet.code,
              name: packet.name,
              products: packet.products.map((product) => ({
                orderDetailOrBonusId: product.is_bonus ? this.getOrderDetailBonusByProductId(
                  product.product_id
                ).id : this.getOrderDetailByProductId(
                  product.product_id
                ).id,
                type: product.is_bonus ? this.getOrderDetailBonusByProductId(
                  product.product_id
                ).type : this.getOrderDetailByProductId(
                  product.product_id
                ).type,
                productId: product.product_id,
                qty: product.shipped_product_qty,
              })),
            })
          );
        }
      },
    },
    async created() {
      if (this.$route.params.id) {
        await this.setOrder();
        await this.setOrderDetails();
        await this.setOrderDetailBonuses()

        this.setPackets();
      }
    },
  };
}
