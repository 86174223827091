<template>
  <BaseCard :title="title">
    <template #action>
      <base-badge>
        {{ getOrder.data.attributes.warehouse_status }}
      </base-badge>
    </template>
    <div class="py-4">
      <dl class="flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">No Penjualan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getOrder.data.attributes.origin_code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getOrder.data.attributes.updatedAt | formatDate }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Kode {{ mapOfficeCategories[viewAs] }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getOrder,
                getOrder.data.relationships['destination-office'].data.id
              )?.attributes?.code ?? '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Nama {{ mapOfficeCategories[viewAs] }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getOrder,
                getOrder.data.relationships['destination-office'].data.id
              )?.attributes?.name ?? '-'
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Alamat {{ mapOfficeCategories[viewAs] }} 1
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getOrder,
                getOrder.data.relationships['destination-office'].data.id
              )?.attributes?.address ?? '-'
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Alamat {{ mapOfficeCategories[viewAs] }} 2
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getOrder,
                getOrder.data.relationships['destination-office'].data.id
              )?.attributes?.address2 ?? '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Nomor Telepon {{ mapOfficeCategories[viewAs] }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getOrder,
                getOrder.data.relationships['destination-office'].data.id
              )?.attributes?.phone ?? '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div
          v-if="getOrder.data.attributes.order_shipment == 'delivery'"
          class="w-full"
        >
          <dt class="text-sm font-bold text-gray-700">Dikirim ke Alamat</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getOrder.data.attributes.shipment_address }},
            {{
              getOrder.data.relationships['village']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['village'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.data.relationships['district']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['district'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.data.relationships['city']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['city'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.data.relationships['province']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['province'].data.id
                  )?.attributes.name
                : '-'
            }}
          </dd>
        </div>
      </dl>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderInformation',
  props: {
    title: {
      default: 'Informasi Pengemasan',
    },
    viewAs: {
      default: 'stockist',
    },
  },
  data() {
    return {
      mapOfficeCategories: {
        stockist: 'Stockist',
        'kantor-pelayanan': 'Kantor Pelayanan',
        'stockist-center': 'Stockist Center',
        member: 'Mitra Usaha',
      },
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
  },
  components: {
    // Your child components here
  },
  methods: {
    getRelationships(resource, id) {
      let data = resource.included?.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
