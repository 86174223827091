<template>
  <div>
    <div class="flex items-center gap-x-2">
      <base-button
        v-if="!retur.attributes.is_valid_for_packing"
        id="simpan"
        :disabled="!canValidate"
        @click="onConfirm"
      >
        Siap Validasi
      </base-button>

      <router-link
        :to="indexRoute"
      >
        <base-button color="white">Selesai</base-button>
      </router-link>
    </div>

    <confirm-modal
      :visible="confirmModal.visible"
      @close="confirmModal.visible = false"
      @saving="onSave"
    />
  </div>
</template>

<script>
import ConfirmModal from '@/components/penjualan/create/confirm-modal.vue';
import { mapActions } from 'vuex';

export default {
  emits: ['confirmed'],
  components: {
    ConfirmModal,
  },
  props: {
    retur: {
      type: Object,
      required: true,
    },
    returDetails: {
      type: Array,
      required: true,
    },
    indexRoute: null
  },
  data() {
    return {
      confirmModal: {
        visible: false,
      },
    };
  },
  computed: {
    canValidate() {
      if (!this.retur.originOffice.id) {
        return false;
      }

      const returDetails = this.returDetails.filter((detail) => detail.id);

      if (!returDetails.length) {
        return false;
      }

      return returDetails.every((detail) => detail.product_qty);
    },
  },
  methods: {
    ...mapActions({
      createRetur: 'returs/createRetur',
      patchValidateRetur: 'returs/validateRetur',
    }),
    onConfirm() {
      this.confirmModal.visible = true;
    },
    async onSave() {
      if (this.retur.id) {
        this.validateRetur(this.retur.id);
      } else {
        const payload = {
          data: {
            type: 'returs',
            attributes: {
              shipment_type: this.retur.attributes.shipment_type,
              is_using_stocks: this.retur.attributes.is_using_stocks,
            },
            relationships: {
              'origin-office': {
                data: {
                  type: 'offices',
                  id: this.retur.originOffice.id,
                },
              },
            },
          },
        };

        const retur = await this.createRetur({
          payload,
        });

        if (retur) {
          this.validateRetur(retur.data.data.id);
        }
      }
    },
    async validateRetur(id) {
      const validated = await this.patchValidateRetur({ id });

      if (validated) {
        this.$emit('confirmed');
      }
    },
  },
};
</script>
