<template>
  <div class="flex items-center space-x-2">
    <base-select
      :expand="false"
      :shadow="false"
      :options="filterStatusOptions"
      v-model="filter.status"
      @change="$emit('filter')"
    />
    <base-search
      class="h-10"
      placeholder="Cari Kode"
      v-model="filter.search"
      v-on:input="$emit('search')"
    />
  </div>
</template>

<script>
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: {
    BaseSearch,
  },
  props: {
    value: Object,
    excludeStatus: String,
  },
  emits: ['input', 'filter', 'search'],
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filterStatusOptions() {
      return [
        {
          key: 'status',
          value: null,
          label: 'Status',
        },
        ...['draft', 'diproses_gudang', 'dikirim', 'valid_saldo', 'selesai']
          .filter((item) => item !== this.excludeStatus)
          .map((item) => ({
            key: item,
            value: item,
            label: item
              .split('_')
              .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
              .join(' '),
          })),
      ];
    },
  },
};
</script>
