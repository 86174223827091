import { debounce } from 'debounce';
import { mapActions, mapGetters } from 'vuex';

export const orderRead = {
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
    }),
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
    }),
    // actions
    async readOrders({ params } = {}) {
      await this.fetchOrders({
        ...(this.readOrdersParams ?? {}),
        ...params,
      });
    },
    async viewOrder(orderId, { params } = {}) {
      await this.fetchOrder({
        id: orderId,
        ...(this.viewOrderParams ?? {}),
        ...params,
      });

      if (this.onOrderViewed) {
        await this.onOrderViewed();
      }
    },

    // helpers
    getOrderIncluded(simpleOrder) {
      return this.getSingleIncludedType('order', this.getOrders, simpleOrder);
    },

    // events
    onCreated() {
      this.readOrders();
    },
    onReload() {
      this.readOrders();
    },
    onReloadDebounce: debounce(function () {
      this.readOrders();
    }, 300),
    onPageChanged(page) {
      this.readOrders({
        params: {
          ...page,
        },
      });
    },
    onViewOrder(orderOrSimpleOrder) {
      if (orderOrSimpleOrder.type === 'simple-orders') {
        this.viewOrder(this.getOrderIncluded(orderOrSimpleOrder).id);
      }
    },
  },
};
