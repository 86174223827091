<template>
  <div class="mx-2 py-2 sm:px-6">
    <base-card class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <retur-table-filter
          v-model="filter"
          exclude-status="valid_saldo"
          @search="onSearch"
          @filter="onFilter"
        />
        <router-link
          :to="{
            name: 'create.retur.pusat',
          }"
        >
          <base-button>
            <!-- Heroicon name: solid/mail -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>
            Tambah Retur
          </base-button>
        </router-link>
      </div>
      <retur-table @page-change="onPageChange" @detail="onDetail" />
    </base-card>

    <retur-detail-modal
      :visible="detailModal.visible"
      @close="detailModal.visible = false"
    />

    <loading v-if="isLoadingRetur" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReturTable from '@/components/retur/retur-table.vue';
import ReturTableFilter from '@/components/retur/retur-table-filter.vue';
import { readRetur } from '@/mixins/retur/read-retur-mixin';
import ReturDetailModal from '@/components/retur/retur-detail-modal.vue';

export default {
  components: {
    ReturTable,
    ReturTableFilter,
    ReturDetailModal,
  },
  mixins: [readRetur],
  data() {
    return {
      originOfficeCategoryName: 'Kantor Pelayanan',
      createReturRouteName: 'edit.retur.pusat',
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser'
    }),
    fetchRetursParams() {
      return {
        'filter[origin_office_id]': this.me.office_id
      }
    }
  }
};
</script>
