<template>
  <base-card class="space-y-5">
    <div class="flex justify-between border-b border-gray-200 pb-5">
      <h3 class="text-lg font-bold leading-6 text-gray-900">Retur</h3>
      <base-badge color="indigo">{{ retur.attributes.status }}</base-badge>
    </div>
    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
      <div :class="'sm:col-span-6'">
        <base-input
          :value="dayjs(retur.attributes.createdAt).format('YYYY-MM-DD')"
          disabled
          label="Tanggal"
          type="date"
          name="tanggal"
          id="tanggal"
          inset
        />
      </div>
      <div class="sm:col-span-3">
        <div class="relative">
          <base-input
            v-model="retur.originOffice.code"
            :label="`Kode ${formatedOriginOfficeType}`"
            :placeholder="`Kode ${formatedOriginOfficeType}`"
            type="text"
            name="kode_stockist"
            id="kode_stockist"
            inset
            :disabled="isDisabled || disabledOriginOffice"
            @change="onBlurOriginOffice"
          />
          <button
            v-if="!isDisabled && !disabledOriginOffice"
            type="button"
            @click="onOpenOriginOfficeModal"
            class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
          >
            <Icon
              class="h-5 w-5 text-gray-500 hover:text-gray-700"
              icon="heroicons:magnifying-glass-circle-20-solid"
            />
          </button>
        </div>
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="retur.originOffice.name"
          disabled
          :label="`Nama ${formatedOriginOfficeType}`"
          :placeholder="`Nama ${formatedOriginOfficeType}`"
          type="text"
          name="nama_stockist"
          id="nama_stockist"
          inset
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="retur.originWarehouse.code"
          disabled
          label="Kode Gudang"
          placeholder="Kode Gudang"
          type="text"
          name="kode_gudang"
          id="kode_gudang"
          inset
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="retur.originArea.code"
          disabled
          label="Wilayah Harga"
          placeholder="Wilayah Harga"
          type="text"
          name="kode_area"
          id="kode_area"
          inset
        />
      </div>
      <div class="sm:col-span-6">
        <label for="metode_pengiriman" class="text-xs font-bold text-gray-700">
          Metode Pengiriman
        </label>
        <div class="mt-1 flex items-center space-x-8">
          <div class="items-center text-sm text-gray-700">
            <input
              v-model="retur.attributes.shipment_type"
              type="radio"
              name="metode_pengiriman"
              value="pickup"
              :disabled="isDisabled"
              class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
            />
            Diambil
          </div>
          <div class="items-center text-sm text-gray-700">
            <input
              v-model="retur.attributes.shipment_type"
              type="radio"
              name="metode_pengiriman"
              value="delivery"
              :disabled="isDisabled"
              class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
            />
            Dikirim
          </div>
        </div>
      </div>
      <div class="sm:col-span-6">
        <label for="is_using_stocks" class="text-xs font-bold text-gray-700">
          Metode Retur
        </label>
        <div class="mt-1 flex items-center space-x-8">
          <div class="items-center text-sm text-gray-700">
            <input
              v-model="retur.attributes.is_using_stocks"
              type="radio"
              name="is_using_stocks"
              :value="true"
              :disabled="isDisabled"
              class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
            />
            Normal (default)
          </div>
          <div class="items-center text-sm text-gray-700">
            <input
              v-model="retur.attributes.is_using_stocks"
              type="radio"
              name="is_using_stocks"
              :value="false"
              :disabled="isDisabled"
              class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
            />
            Tanpa Barang
          </div>
        </div>
      </div>
    </div>

    <office-modal
      :is-visible="originOfficeModal.visible"
      @onPageChangeKantor="onChangePageOriginOfficeModal"
      @onSearchKantor="onSearchOriginOfficeModal"
      @change-office="onSelectOriginOfficeModal"
      @closeKantorModal="originOfficeModal.visible = false"
    />
  </base-card>
</template>
<script>
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import OfficeModal from '@/components/office/office-modal.vue';

export default {
  name: 'ReturCreateForm',
  components: {
    OfficeModal,
  },
  props: {
    retur: Object,
    originOfficeType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledOriginOffice: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-origin-office'],
  data() {
    return {
      originOfficeModal: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
    }),
    isDisabled() {
      return this.disabled || !!this.retur.id;
    },
    formatedOriginOfficeType() {
      return this.originOfficeType
        .split('_')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ');
    },
    originOfficeTypeCategoryId() {
      return {
        kantor_pelayanan: 2,
        stockist_center: 3,
        stockist: 4,
        supplier: 6,
      }[this.originOfficeType];
    },
  },
  methods: {
    ...mapActions({
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchOffices: 'offices/fetchOffices',
      createAlert: 'alert/createAlert',
    }),
    dayjs,
    onOpenOriginOfficeModal() {
      this.loadOriginOffices();

      this.originOfficeModal.visible = true;
    },
    onChangePageOriginOfficeModal(page) {
      this.loadOriginOffices(page);
    },
    onSearchOriginOfficeModal(q) {
      this.loadOriginOffices({
        'filter[search]': q,
      });
    },
    async onSelectOriginOfficeModal(simpleOffice) {
      const office = await this.fetchOfficeById({
        office_id: simpleOffice.relationships.office.data.id,
        params: {
          'fields[offices]': 'code,name,current-warehouse,area',
          'fields[warehouses]': 'code',
          'fields[areas]': 'code',
          include: 'current-warehouse,area',
        },
      });

      this.$emit('change-origin-office', office.data.data);

      this.originOfficeModal.visible = false;
    },
    async onBlurOriginOffice() {
      const offices = await this.fetchOffices({
        'fields[simple-offices]': 'office',
        'fields[offices]': 'code',
        include: 'office',
        'filter[code]': this.retur.originOffice.code,
        'filter[office_category_id]': this.originOfficeTypeCategoryId,
      });

      if (offices.data.data.length) {
        this.onSelectOriginOfficeModal(offices.data.data[0]);
      } else {
        this.createAlert({
          data: `Kode ${this.formatedOriginOfficeType} tidak ditemukan`,
          status: 'error',
        });
      }
    },
    loadOriginOffices(params = {}) {
      this.fetchSimpleOffices({
        'filter[office_category_id]': this.originOfficeTypeCategoryId,
        'fields[simple-offices]': 'office',
        'fields[offices]': 'code,name,address,phone',
        include: 'office',
        'page[limit]': 5,
        ...params,
      });
    },
  },
};
</script>
