<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Informasi Supplier
        </h3>
      </div>
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <div
              class="field-inset-default relative w-full"
              :class="{ 'bg-gray-100': is_detail }"
            >
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Kode PO
              </label>
              <input
                v-model="dataBrgMasuk.no_po"
                disabled
                type="text"
                name="kode_po"
                id="kode_po"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                :class="{ 'bg-gray-100': is_detail }"
                placeholder="Masukkan Kode Supplier"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="dataBrgMasuk.po_date"
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="kode_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Kode Supplier
              </label>
              <input
                v-model="dataBrgMasuk.kode_supplier"
                type="text"
                name="kode_supplier"
                id="kode_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Kode Supplier..."
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Supplier
              </label>
              <input
                v-model="dataBrgMasuk.nama_supplier"
                type="text"
                name="nama_supplier"
                id="nama_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Nama Supplier..."
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <SupplierBatchForm
      v-model="dataBrgMasuk"
      :packets="packets"
      @remove-product="onRemoveProduct"
      @remove-batch="onRemoveItem"
      @push-batch="onPushBatch"
      @batch-input="onBatchInput"
      @product-input="onProductInput"
    />

    <div class="pt-5">
      <div class="flex justify-end">
        <base-button
          id="simpan"
          :disabled="!saveable"
          :class="saveable ? '' : 'cursor-not-allowed opacity-50'"
          @click="addIncomingGood"
          class="tooltip"
        >
          Simpan
          <p style="width: fit-content" v-if="!saveable" class="tooltiptext">
            Jumlah barang harus pas dan form terisi semua
          </p>
        </base-button>
        <base-button color="white" @click="goBack()"> Kembali </base-button>
      </div>
    </div>

    <!-- Detail Modal -->
    <SupplierPengeluaranModal
      :visible="success"
      redirect-on-close
      :redirect-to="{ name: 'gudang.pengeluaran.dari-supplier' }"
    >
      <template #action="{ order }">
        <div class="flex justify-end space-x-2">
          <button
            @click="handleValidate(order)"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            Validasi
          </button>
          <button
            @click="handleRedirect"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </template>
    </SupplierPengeluaranModal>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="failedModal"
          link="/gudang/pengeluaran/barang-dari-supplier"
        >
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError[0].detail }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import SupplierPengeluaranModal from '@/components/supplier/SupplierPengeluaranModal';
import SupplierBatchForm from '@/components/supplier/SupplierBatchForm.vue';
// import Datatable from '@/components/base/Datatable';
import { clone } from '@/services/utils.service.js';

export default {
  name: 'TambahPengeluaran',
  components: {
    Modal,
    Loading,
    // Datatable,
    SupplierBatchForm,
    SupplierPengeluaranModal,
  },
  data() {
    return {
      id_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      search: null,
      dataBrgMasuk: {
        id_po: null,
        origin_warehouse_id: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      },
      packets: [],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataBrgMasuk.id_po = this.$route.params.id;
      this.fetchOrder({
        id: this.dataBrgMasuk.id_po,
        include:
          'destination-office,origin-office,origin-warehouse,destination-warehouse,order-details,stock-movements,order-details',
        // fields: {
        //   orders:
        //     'createdAt,updatedAt,origin_code,created_by,destination_code,final_price,is_completed,is_valid_for_payment,is_shipped,final_price,grand_total_weight,item_count,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details,stock-movements,order-details',
        //   offices: 'code,name,address,address2',
        //   'order-details':
        //     'product_id,product_code,product_qty,product_weight,product_weight,total_weight,product_not_received,total_price,is_completed,product_name,product_price',
        // },
      }).then((response) => {
        this.setOrder(response.data);
      });
    }

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getError: 'stock_movements/getError',
    }),
    packetsPayload() {
      return [
        {
          name: 'Koli 1',
          products: this.packets.map((data) => ({
            product_type: data.product_type,
            product_id: data.product_id,
            shipped_product_qty: parseInt(data.product_not_received),
            no_batch: data.no_batch,
            expired_at: data.expired_at,
            batches: data.batch.map((item) => {
              return {
                no_batch: item.attributes.no_batch ?? '',
                product_qty: parseInt(item.attributes.jumlah),
                expires_at: item.attributes.expired_at ?? '',
              };
            }),
          })),
        },
      ];
    },
    saveable() {
      return this.packets
        .map((i) => {
          const subtotal = i.batch.reduce(
            (acc, curr) => acc + parseInt(curr.attributes.jumlah),
            0
          );
          return (
            subtotal === parseInt(i.product_not_received) &&
            i.batch.every((batch) => !!batch.attributes.expired_at) &&
            i.batch.every((batch) => !!batch.attributes.no_batch)
          );
        })
        .every((bool) => bool); //klo ada yg false, jadiin false semua
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      createStockMovement: 'stock_movements/createStockMovement',
      updateStockMovement: 'stock_movements/updateStockMovement',
    }),
    handleValidate(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateStockMovement(payload).then(() => {
        this.handleRedirect();
      });
    },
    handleRedirect() {
      this.$router.push({ name: 'gudang.pengeluaran.dari-supplier' });
    },
    successModal() {
      this.success = !this.success;
      this.openDetail = true;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    changeTotal(qty, index) {
      this.dataBrgMasuk.details[index].attributes.total_price =
        this.dataBrgMasuk.details[index].attributes.product_not_received *
        this.dataBrgMasuk.details[index].attributes.product_price;
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getOrderRelationships(id) {
      let data = this.getOrder.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    onRemoveProduct(index) {
      this.dataBrgMasuk.details.splice(index, 1);
      this.packets.splice(index, 1);
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_id: param.product_id,
          shipped_product_qty: param.product_not_received,
          no_batch: param.no_batch,
          expired_at: param.expired_at,
        };
        dataDetail.push(data);
      });
      return {
        name: 'Koli 1',
        products: dataDetail,
      };
    },
    addIncomingGood() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_warehouse_id: this.dataBrgMasuk.origin_warehouse_id,
            destination_warehouse_id:
              this.dataBrgMasuk.destination_warehouse_id,
            origin_office_id: this.dataBrgMasuk.origin_office_id,
            destination_office_id: this.dataBrgMasuk.destination_office_id,
            packets: this.packetsPayload,
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataBrgMasuk.id_po,
              },
            },
          },
        },
      };
      // console.log({payload})
      this.createStockMovement(payload).then((response) => {
        if (response) {
          this.fetchOrder({ id: this.dataBrgMasuk.id_po }).then(() => {
            this.success = true;
          });
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.dataBrgMasuk = {
        id_po: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      };
    },
    goBack() {
      this.$router.go(-1);
    },
    setOrder(order) {
      this.dataBrgMasuk.no_po = order.data.attributes.destination_code;
      this.dataBrgMasuk.id_po = order.data.id;
      this.dataBrgMasuk.no_po = order.data.attributes.destination_code;
      this.dataBrgMasuk.origin_office_id =
        order.data.relationships['origin-office'].data.id;
      this.dataBrgMasuk.destination_office_id =
        order.data.relationships['destination-office'].data.id;
      this.dataBrgMasuk.id_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).id;
      this.dataBrgMasuk.kode_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).attributes.code;
      this.dataBrgMasuk.nama_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).attributes.name;

      this.dataBrgMasuk.details = order.included.filter(
        (it) => it.type === 'order-details'
        // && it.attributes.is_completed === 0
      );
      this.packets = this.dataBrgMasuk.details.map((data) => ({
        product_type: data.attributes.product_type,
        product_id: data.attributes.product_id,
        shipped_product_qty: data.attributes.product_not_received,
        product_not_received: data.attributes.product_not_received,
        no_batch: null,
        expired_at: null,
        batch: [clone(this.dataBrgMasuk.details[0])],
      }));

      this.dataBrgMasuk.origin_warehouse_id =
        order.data.relationships['origin-warehouse'].data.id;
      this.dataBrgMasuk.destination_warehouse_id =
        order.data.relationships['destination-warehouse'].data.id;
    },
    onPushBatch(index) {
      this.packets[index].batch.push(clone(this.dataBrgMasuk.details[index]));
    },
    onRemoveItem({ index, i }) {
      this.packets[index].batch.splice(i, 1);
    },
    onBatchInput() {
      this.dataBrgMasuk = clone(this.dataBrgMasuk);
      this.packets = clone(this.packets);
    },
    onProductInput() {
      this.dataBrgMasuk = clone(this.dataBrgMasuk);
      this.packets = clone(this.packets);
    },
  },
};
</script>
