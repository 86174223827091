import { mapActions, mapGetters } from 'vuex';

export const createRetur = {
  data() {
    return {
      retur: {
        id: null,
        originOffice: {
          id: null,
          code: null,
          name: null,
        },
        originWarehouse: {
          id: null,
          code: null,
        },
        originArea: {
          code: null,
        },
        attributes: {
          createdAt: new Date(),
          is_using_stocks: true,
          shipment_type: 'pickup',
          is_valid_for_packing: false,
          status: 'Draft',
        },
      },
      returDetails: [
        {
          id: null,
          product_id: null,
          product_code: null,
          original_product_code: null,
          product_name: null,
          current_stock: null,
          product_qty: null,
          original_product_qty: null,
          product_weight: null,
          point_value: null,
          bonus_value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getRetur: 'returs/getRetur',
      isLoadingSimpleOffices: 'simple_offices/getLoading',
      isLoadingOffices: 'offices/getLoading',
      isLoadingStocks: 'stocks/getLoading',
      isLoadingWarehouses: 'warehouses/getLoading',
      isLoadingReturs: 'returs/getLoading',
      isLoadingReturDetails: 'retur_details/getLoading',
    }),
    canAddReturDetails() {
      return (
        !!this.retur.originOffice.id
      );
    },
    isLoading() {
      return (
        this.isLoadingStocks ||
        this.isLoadingSimpleOffices ||
        this.isLoadingOffices ||
        this.isLoadingReturs ||
        this.isLoadingWarehouses ||
        this.isLoadingReturDetails
      );
    },
  },
  methods: {
    ...mapActions({
      fetchRetur: 'returs/fetchRetur',
    }),
    onChangeOriginOffice(office) {
      this.setOriginOffice(office);
    },
    onReturCreated(retur) {
      this.$router.push(`${this.createFormRoutePath}/${retur.id}`);

      this.retur.id = retur.id;
    },
    onConfirmed() {
      this.$router.push(this.indexRoute);
    },
    setOriginOffice(office) {
      this.retur.originOffice.id = office.id;
      this.retur.originOffice.code = office.attributes.code;
      this.retur.originOffice.name = office.attributes.name;

      const warehouse = this.getSingleIncluded(
        this.getOffice,
        office.relationships['current-warehouse'].data.id
      );

      this.retur.originWarehouse.id = warehouse.id;
      this.retur.originWarehouse.code = warehouse.attributes.code;

      if (office.relationships['area'].data) {
        const area = this.getSingleIncluded(
          this.getOffice,
          office.relationships['area'].data.id
        );

        this.retur.originArea.code = area.attributes.code;
      }
    },
    async setReturById(id) {
      const res = await this.fetchRetur({
        id,
        include: 'origin-office,origin-warehouse,details',
        'fields[returs]':
          'createdAt,updatedAt,is_using_stocks,is_valid_for_packing,shipment_type,origin-office,origin-warehouse,details,retur_status',
        'fields[offices]': 'code,name',
        'fields[warehouses]': 'code,area_code',
        'fields[retur-details]':
          'product_id,product_code,product_name,current_stock,product_qty,product_qty,product_weight,product_point,product_bonus',
      });

      if (res.data.data) {
        const retur = res.data.data;

        this.retur.id = retur.id;
        this.retur.attributes.shipment_type = retur.attributes.shipment_type;
        this.retur.attributes.is_using_stocks =
          retur.attributes.is_using_stocks;
        this.retur.attributes.createdAt = retur.attributes.createdAt;
        this.retur.attributes.is_valid_for_packing =
          retur.attributes.is_valid_for_packing;
        this.retur.attributes.status = retur.attributes.retur_status;

        const originOffice = this.getSingleIncluded(
          res.data,
          retur.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          res.data,
          retur.relationships['origin-warehouse'].data.id
        );

        const returDetails = this.getIncludedByType(res.data, 'retur-details');

        this.retur.originOffice.id = originOffice.id;
        this.retur.originOffice.code = originOffice.attributes.code;
        this.retur.originOffice.name = originOffice.attributes.name;

        this.retur.originWarehouse.id = originWarehouse.id;
        this.retur.originWarehouse.code = originWarehouse.attributes.code;

        this.retur.originArea.code = originWarehouse.attributes.area_code;

        this.returDetails = returDetails.map((returDetail) => ({
          id: returDetail.id,
          product_id: returDetail.attributes.product_id,
          product_code: returDetail.attributes.product_code,
          original_product_code: returDetail.attributes.product_code,
          product_name: returDetail.attributes.product_name,
          current_stock:
            returDetail.attributes.current_stock +
            returDetail.attributes.product_qty,
          product_qty: returDetail.attributes.product_qty,
          original_product_qty: returDetail.attributes.product_qty,
          product_weight: returDetail.attributes.product_weight,
          point_value: returDetail.attributes.product_point,
          bonus_value: returDetail.attributes.product_bonus,
        }));

        if (!retur.attributes.is_valid_for_packing) {
          this.returDetails.push({
            id: null,
            product_id: null,
            product_code: null,
            original_product_code: null,
            product_name: null,
            current_stock: null,
            product_qty: null,
            original_product_qty: null,
            product_weight: null,
            point_value: null,
            bonus_value: null,
          });
        }
      }
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.setReturById(this.$route.params.id);
    }
  },
};
