<template>
  <Datatable
    :total="getReturs.meta.page.total"
    :perPage="getReturs.meta.page.perPage"
    :currentPage="getReturs.meta.page.currentPage"
    @pagechanged="page => $emit('page-change', page)"
    :is-empty="!getReturs.data.length"
    id="table_retur"
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nomor Retur
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode Stockist/Kode Gudang
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Harga
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Retur
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody class="relative">
      <tbody v-if="getReturs.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getReturs.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.prevent="$emit('detail', data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <div class="text-sm font-bold text-gray-900">
              {{ data.attributes.origin_code }}
            </div>
            <div class="text-xs text-gray-500">
              {{ data.attributes.updatedAt | formatDate }}
            </div>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              getSingleIncluded(
                getReturs,
                data.relationships['origin-office'].data.id
              ).attributes.code
            }}
            /
            {{
              getSingleIncluded(
                getReturs,
                data.relationships['origin-warehouse'].data.id
              ).attributes.code
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.total_price | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
          >
            <base-badge color="indigo">
              {{ data.attributes.retur_status }}
            </base-badge>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>
<script>
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import BaseBadge from '@/components/base/BaseBadge.vue';

export default {
  components: {
    Datatable,
    BaseBadge,
  },
  emits: ['detail', 'page-change'],
  computed: {
    ...mapGetters({
      getReturs: 'returs/getReturs',
    }),
  },
};
</script>
