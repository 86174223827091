<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')">
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">Retur</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Detail Retur</p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div v-if="visible" class="border-t border-gray-200 pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nomor Retur</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getRetur.data.attributes.origin_code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Kantor</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  getSingleIncluded(
                    getRetur,
                    getRetur.data.relationships['origin-office'].data.id
                  ).attributes.code
                }}
                /
                {{
                  getSingleIncluded(
                    getRetur,
                    getRetur.data.relationships['origin-office'].data.id
                  ).attributes.name
                }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getRetur.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div v-if="isUsingPrice" class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Total Harga</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getRetur.data.attributes.total_price | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getRetur.data.attributes.retur_status }}
              </dd>
            </div>
            <div class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <Datatable :paginated="false" :footer="false">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Jumlah
                        </th>
                        <th
                          v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        ></th>
                        <th
                          v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Total Harga
                        </th>
                        <th
                          v-if="getRetur.data.attributes.is_received"
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        ></th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <template
                        v-for="(product, index) in getIncludedByType(
                          getRetur,
                          'retur-details'
                        )"
                      >
                        <tr
                          :key="index"
                          class="bg-white hover:bg-green-100"
                          :class="{
                            'cursor-pointer':
                              getRetur.data.attributes.is_received,
                          }"
                          @click="onToggleActiveRow(index)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ product.attributes.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ product.attributes.product_name }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{ product.attributes.product_qty | toCurrency }}
                          </td>
                          <td
                            v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                            colspan="2"
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{ product.attributes.total_price | toCurrency }}
                          </td>
                          <td
                            v-if="getRetur.data.attributes.is_received"
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mx-auto h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                v-if="activeRowIndex === index"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M5 15l7-7 7 7"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                v-else
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                        <template v-if="activeRowIndex === index">
                          <tr
                            v-if="product.attributes.expired_count"
                            class="bg-gray-50"
                            :key="`detail_kadaluarsa_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Kadaluarsa
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.expired_count }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                product.attributes.expired_price | toCurrency
                              }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                (product.attributes.expired_price *
                                  product.attributes.expired_count)
                                  | toCurrency
                              }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.damaged_count"
                            class="bg-gray-50"
                            :key="`detail_rusak_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Rusak
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.damaged_count }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                product.attributes.damaged_price | toCurrency
                              }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                (product.attributes.damaged_price *
                                  product.attributes.damaged_count)
                                  | toCurrency
                              }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.suitable_for_sale_count"
                            class="bg-gray-50"
                            :key="`detail_layak_jual_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Layak Jual
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.suitable_for_sale_count }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                product.attributes.suitable_for_sale_price
                                  | toCurrency
                              }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                (product.attributes.suitable_for_sale_price *
                                  product.attributes.suitable_for_sale_count)
                                  | toCurrency
                              }}
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-if="product.attributes.unsuitable_for_sale_count"
                            class="bg-gray-50"
                            :key="`detail_tidak_layak_jual_${index}`"
                          >
                            <td></td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Tidak Layak Jual
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.attributes.unsuitable_for_sale_count }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                product.attributes.unsuitable_for_sale_price
                                  | toCurrency
                              }}
                            </td>
                            <td
                              v-if="isUsingPrice && getRetur.data.attributes.is_completed"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                (product.attributes.unsuitable_for_sale_price *
                                  product.attributes.unsuitable_for_sale_count)
                                  | toCurrency
                              }}
                            </td>
                            <td></td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </template>
                </Datatable>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close'],
  data() {
    return {
      activeRowIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      getRetur: 'returs/getRetur',
    }),
    isUsingPrice() {
      return this.getSingleIncluded(this.getRetur, this.getRetur.data.relationships['origin-office'].data.id).attributes.office_type !== 'kantor_pelayanan'
    }
  },
  watch: {
    visible() {
      this.activeRowIndex = null;
    },
  },
  methods: {
    onToggleActiveRow(index) {
      if (!this.getRetur.data.attributes.is_received) {
        return;
      }

      if (index === this.activeRowIndex) {
        this.activeRowIndex = null;
      } else {
        this.activeRowIndex = index;
      }
    },
  },
};
</script>
