<template>
  <OrderDetailModal
    title="Detail Order Barang"
    description="Data Order Barang"
    validation-ignore-payment-status
    :visible="visible"
    :order="getOrder.data"
    :with-payments="false"
    @edit="handleEdit"
    @close="handleClose"
    @refresh="handleRefresh"
    with-delete-confirmation
    deleteable
    editable
  >
    <template #detail="{ order }">
      <dl class="flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">No PO</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.destination_code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Tanggal</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ dayjs(order.attributes.updatedAt).format('ll LT') }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Kode Kantor</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              order.relationships['origin-office'].data
                ? getRelationships(
                    getOrder,
                    order.relationships['origin-office'].data.id
                  )?.attributes.code
                : '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.created_by?.office?.name }}/{{
              order.attributes.created_by?.user?.name
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Nama Supplier</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.name ?? '-'
                : '-'
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 1</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes?.address ?? '-'
                : '-'
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 2</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes?.address2 ?? '-'
                : '-'
            }}
          </dd>
        </div>
      </dl>
    </template>

    <template #order-details="{ orderDetails, order }">
      <!-- Daftar Pengiriman -->
      <template v-if="withStockMovement">
        <p class="mb-1 text-sm font-bold text-gray-900">Daftar Pengiriman</p>
        <Datatable :paginated="false" :footer="false" class="">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  KODE KEMASAN
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Tanggal
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  TOTAL BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  STATUS
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="!getStockMovements.data.length">
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  Belum ada pengiriman
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="cursor-pointer bg-white hover:bg-green-100"
                v-for="(stockMovement, index) in getStockMovements.data"
                :key="index"
                v-on:click="handleClickStockMovement(stockMovement)"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ stockMovement.attributes.code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ stockMovement.attributes.createdAt | formatDate }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                >
                  {{
                    Object.keys(stockMovement.attributes.product_summaries)
                      .length
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                >
                  <span
                    v-if="stockMovement.attributes.is_received"
                    class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                  >
                    Diterima
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                  >
                    Belum diterima
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </template>

      <!-- Daftar Barang -->
      <p class="mb-1 mt-6 text-sm font-bold text-gray-900">Daftar Barang</p>
      <Datatable :paginated="false" :footer="false" class="">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TIPE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                JUMLAH
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                HARGA SUPP
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                BERAT
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL HARGA
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL BERAT
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              class="bg-white"
              v-for="(barang, index) in orderDetails"
              :key="index"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{ barang.attributes.product_code }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-900"
              >
                {{ barang.attributes.product_type === 'free' ? 'Reguler' : 'Promo' }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_qty | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_weight }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_weight }}
              </td>
            </tr>
          </tbody>
          <!-- Informasi Total -->
          <tfoot>
            <tr class="bg-white">
              <td
                colspan="4"
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                Total
              </td>

              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_weight }}
              </td>
            </tr>
          </tfoot>
        </template>
      </Datatable>
    </template>
  </OrderDetailModal>
</template>

<script>
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import Datatable from '@/components/base/Datatable';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: { OrderDetailModal, Datatable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    redirectOnClose: {
      type: Boolean,
      default: false,
    },
    withStockMovement: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'refresh', 'detail-stock-movement'],
  data() {
    return {
      visibleDetailStockMovement: false,
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getStockMovements: 'stock_movements/getStockMovements',
    }),
  },
  methods: {
    ...mapActions({
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    dayjs,
    handleClose() {
      if (this.redirectOnClose) {
        this.$router.push('/gudang/pembelian');
      } else {
        this.$emit('close');
      }

      this.visibleDetailStockMovement = false;
    },
    handleEdit(orderId) {
      this.$router.push(`/gudang/pembelian/${orderId}`);
    },
    handleRefresh() {
      if (this.redirectOnClose) {
        this.$router.push('/gudang/pembelian');
      } else {
        this.$emit('refresh');
      }
    },
    async handleClickStockMovement(item) {
      this.$emit('detail-stock-movement', item.id);
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
  },
};
</script>
