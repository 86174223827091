<template>
  <baseModal
    :showing="visible"
    @close="handleClose"
    :showClose="true"
    size="max-w-7xl"
  >
    <div class="space-y-5">
      <div class="flex items-center justify-between">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Stok</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Data stok berdasarkan gudang yang dipilih
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-32" />
        </div>
      </div>
      <div class="space-y-4 border-t border-gray-200 pt-5">
        <div class="flex justify-end">
          <base-search
            v-model="filter.search"
            @input="handleSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <Datatable
              :total="getWarehouseStock.meta.page.total"
              :perPage="getWarehouseStock.meta.page.perPage"
              :currentPage="getWarehouseStock.meta.page.currentPage"
              @pagechanged="handleChangePage"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode / Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Berat
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      PV
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      BV
                    </th>
                    <th
                      v-if="columns.includes('price')"
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Harga
                    </th>
                    <th class="w-12"></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <template v-for="(data, index) in getWarehouseStock.data">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      :key="data.id"
                      :class="getClasses(data, index)"
                      @click="handleClickProduct(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                      >
                        <p class="">
                          {{ data.attributes.product_code }}
                        </p>
                        <p class="text-xs font-light">
                          {{ data.attributes.product_name }}
                        </p>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.orderable_stock | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.stock_weight }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          getSingleIncluded(
                            getWarehouseStock,
                            data.relationships.product.data.id
                          ).attributes.point
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          getSingleIncluded(
                            getWarehouseStock,
                            data.relationships.product.data.id
                          ).attributes.bonus | toCurrency
                        }}
                      </td>
                      <td
                        v-if="columns.includes('price')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.product_price | toCurrency }}
                      </td>
                      <td
                        @click.stop="showStockDropdown(data)"
                        class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500 hover:bg-green-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="h-6 w-6 transition-all"
                          :class="
                            data.id == selectedStock.id ? 'rotate-180' : null
                          "
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </td>
                    </tr>
                    <tr
                      :key="data.attributes.product_code"
                      v-if="data.id == selectedStock.id"
                    >
                      <td
                        aria-colspan="7"
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        <p>Tanggal Kedaluwarsa:</p>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.expired_at ?? '-' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="handleClose"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </div>
  </baseModal>
</template>

<script>
import debounce from 'debounce';
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import baseModal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: { Datatable, baseModal, BaseSearch },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    forceClick: {
      type: Boolean,
    },
    columns: {
      type: Array,
      default: () => ['price']
    }
  },
  emits: ['close', 'click-product'],
  data() {
    return {
      selectedStock: {},
      filter: {
        search: null,
      },
      page: {
        size: 5,
        number: 1,
      },
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.resetFilterPage();
        this.loadWarehouseStock();
      }
    },
  },
  computed: {
    ...mapGetters({
      getWarehouseStock: 'warehouses/getWarehouseStock',
    }),
  },
  methods: {
    ...mapActions({
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
    }),
    getClasses(stock, index) {
      return [
        this.forceClick
          ? ''
          : stock.attributes.is_orderable
          ? ''
          : 'cursor-not-allowed bg-gray-100',
        index % 2 === 0 ? 'bg-white' : 'bg-gray-50]',
      ].join(' ');
    },
    loadWarehouseStock() {
      this.fetchWarehouseStock({
        ...this.params,
        'filter[search]': this.filter.search,
        'page[size]': this.page.size,
        'page[number]': this.page.number,
        'fields[products]': 'point,bonus',
        'filter[has_stock_real]': true,
        include: 'product',
      });
    },
    resetPage() {
      this.page.number = 1;
    },
    resetFilterPage() {
      this.resetPage();
      this.filter.search = null;
    },
    handleClose() {
      this.$emit('close');
    },
    handleSearch: debounce(function () {
      this.resetPage();
      this.loadWarehouseStock();
    }, 500),
    handleChangePage(page) {
      this.page.number = page;
      this.loadWarehouseStock();
    },
    handleClickProduct(product) {
      if (!product.attributes.is_orderable && !this.forceClick) {
        return;
      }
      this.$emit('click-product', product);
    },
    showStockDropdown(stock) {
      if (this.selectedStock.id === stock.id) {
        this.selectedStock = {};
        return;
      }
      this.selectedStock = stock;
    },
  },
};
</script>
