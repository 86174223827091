import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';

export const readRetur = {
  data() {
    return {
      filter: {
        search: null,
        is_valid_for_packing: null,
        is_shipped: null,
        is_received: null,
        is_completed: null,
        status: null,
      },
      detailModal: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOfficeCategoryByName: 'office_categories/getOfficeCategoryByName',
      isLoadingRetur: 'returs/getLoading',
    }),
    computedFilter() {
      if (this.filter.status === 'draft') {
        return {
          is_valid_for_packing: false,
          is_shipped: null,
          is_received: null,
          is_completed: null,
        };
      }

      if (this.filter.status === 'diproses_gudang') {
        return {
          is_valid_for_packing: true,
          is_shipped: false,
          is_received: null,
          is_completed: null,
        };
      }

      if (this.filter.status === 'dikirim') {
        return {
          is_valid_for_packing: null,
          is_shipped: true,
          is_received: false,
          is_completed: null,
        };
      }

      if (this.filter.status === 'valid_saldo') {
        return {
          is_valid_for_packing: null,
          is_shipped: null,
          is_received: true,
          is_completed: false,
        };
      }

      if (this.filter.status === 'selesai') {
        return {
          is_valid_for_packing: null,
          is_shipped: null,
          is_received: null,
          is_completed: true,
        };
      }

      return {
        is_valid_for_packing: null,
        is_shipped: null,
        is_received: null,
        is_completed: null,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchReturs: 'returs/fetchReturs',
      fetchRetur: 'returs/fetchRetur',
    }),
    onSearch: debounce(function () {
      this.loadReturs();
    }, 300),
    onFilter() {
      this.loadReturs();
    },
    onPageChange(page) {
      this.loadReturs(page);
    },
    async onDetail(data) {
      if (data.attributes.is_valid_for_packing) {
        const res = await this.fetchRetur({
          id: data.id,
          include: 'origin-office,details',
          'fields[returs]':
            'origin_code,origin-office,details,updatedAt,total_price,retur_status,is_completed,is_received,is_using_stocks',
          'fields[offices]': 'code,name,office_type',
          'fields[retur-details]':
            'product_code,product_name,product_qty,total_price,expired_count,expired_price,damaged_count,damaged_price,unsuitable_for_sale_count,unsuitable_for_sale_price,suitable_for_sale_count,suitable_for_sale_price',
        });

        if (res) {
          this.detailModal.visible = true;
        }
      } else {
        this.$router.push({
          name: this.createReturRouteName,
          params: {
            id: data.id,
          },
        });
      }
    },
    loadReturs(page) {
      const originCategory = this.getOfficeCategoryByName(
        this.originOfficeCategoryName
      );
      const pusatCategory = this.getOfficeCategoryByName('Pusat');

      this.fetchReturs({
        params: {
          include: 'origin-office,origin-warehouse',
          'filter[origin_office_category_id]': originCategory.id,
          'filter[destination_office_category_id]': pusatCategory.id,
          'filter[search]': this.filter.search,
          'filter[is_valid_for_packing]':
            this.computedFilter.is_valid_for_packing,
          'filter[is_shipped]': this.computedFilter.is_shipped,
          'filter[is_received]': this.computedFilter.is_received,
          'filter[is_completed]': this.computedFilter.is_completed,
          'page[size]': 5,
          'page[number]': page,
          'fields[returs]':
            'origin_code,updatedAt,origin-office,origin-warehouse,total_price,retur_status,is_valid_for_packing',
          'fields[offices]': 'code',
          'fields[warehouses]': 'code',
          sort: '-createdAt',
          ...this.fetchRetursParams
        },
      });
    },
  },
  created() {
    this.loadReturs();
  },
};
