<template>
  <div class="mx-4 space-y-4 py-4 md:mx-6 md:py-6">
    <loading
      v-if="
        getOrderLoading || getOrderDetailsLoading || getStockMovementLoading
      "
    />
    <OrderInformation view-as="kantor-pelayanan" />

    <CreateStockMovementForm
      :packets="packets"
      :getOrderDetailOrBonusById="getOrderDetailOrBonusById"
      @remove-packet="onRemovePacket"
      @remove-packet-product="onRemovePacketProduct"
      @blur-packet-product-qty="
        ({ e, packetIndex, productIndex }) =>
          onBlurPacketProductQty(e, packetIndex, productIndex)
      "
      @add-packet-product="onAddPacketProduct"
      @add-packet="onAddPacket"
    />

    <StockRemainderTable :orderDetailsRemainder="orderDetailsRemainder" />

    <div class="flex justify-end gap-x-2">
      <base-button :disabled="!sumPacketProductQty" @click="onSubmit">
        Dikemas
      </base-button>
      <base-button color="white" to="/gudang/pengeluaran/barang-ke-kp">
        Kembali
      </base-button>
    </div>

    <!-- MODAL PRODUK -->
    <PacketProductCheckModal
      :visible="packetProductModal.visible"
      :order-details="orderDetailsRemainder"
      with-scan-qr-code
      @close="packetProductModal.visible = false"
      @confirm="onConfirmPacketProduct"
    />

    <ValidationModal
      v-model="visibleConfirmationModal"
      title="Buat Koli"
      description="Apakah anda yakin data yang dibuat sudah benar?"
      @submit="onConfirmPackets"
      @close="visibleConfirmationModal = !visibleConfirmationModal"
    />

    <ValidationModal
      type="info"
      v-model="visibleWarningRemainderModal"
      title="Barang sisa ditemukan"
      description="Barang yang sisa akan dimasukkan ke Susulan, apakah anda yakin akan melanjutkan?"
      @submit="onConfirmPackets"
      @close="visibleWarningRemainderModal = !visibleWarningRemainderModal"
    />
    <!-- print -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="getOrder.data.attributes.code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printStockMovement"
    >
      <LayoutPrintStockMovement
        slot="pdf-content"
        :stock-movement="printStockMovementAttributes"
      />
    </vue-html2pdf>
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import LayoutPrintStockMovement from '@/layouts/LayoutPrintStockMovement.vue';
import VueHtml2pdf from 'vue-html2pdf';
import PacketProductCheckModal from '@/components/packet/PacketProductCheckModal.vue';
import { pengemasanMixin } from '@/mixins/koli/pengemasan.mixin';
import OrderInformation from '@/components/pengeluaran/order-information.vue';
import CreateStockMovementForm from '@/components/pengeluaran/create-stock-movement-form.vue';
import StockRemainderTable from '@/components/pengeluaran/stock-remainder-table.vue';
import ValidationModal from '@/components/modal/validation-modal.vue';

export default {
  name: 'DetailPengeluaran',
  components: {
    Loading,
    VueHtml2pdf,
    PacketProductCheckModal,
    LayoutPrintStockMovement,
    OrderInformation,
    CreateStockMovementForm,
    StockRemainderTable,
    ValidationModal,
  },
  mixins: [pengemasanMixin({ indexPath: '/gudang/pengeluaran/barang-ke-kp' })],
  data() {
    return {
      indexPath: '/gudang/pengeluaran/barang-ke-kp',
    };
  },
};
</script>
